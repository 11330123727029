import React from 'react';
import _ from 'underscore';

import wurd from 'wurd-react';
import * as visitHelpers from '../../../utils/visit-helpers';

import PropTypes from 'prop-types';
import store from '../../../store';


export default class TimeSelect extends React.Component {
  static propTypes = {
    selectedDate: PropTypes.string,
    onChange: PropTypes.func,
    timeslots: PropTypes.array,
  };

  getTimeslots() {
    const { timeslots, selectedDate } = this.props;

    if (!timeslots || !selectedDate) return [];

    const timeList = _.find(timeslots, (item) => item.date === selectedDate);

    return timeList ? timeList.times : [];
  }

  render() {
    const { selectedDate, className = '', timeslots, ...props } = this.props;
    const { settings } = store.get();

    return (
      <select
        className={`form-control ${className}`}
        {...props}
      >
        {
          (!selectedDate)
            ? <option value="">{wurd.text('timeForm.time.placeholder_loading')}</option>
            : <option value="">{wurd.text('timeForm.time.placeholder')}</option>
        }

        {this.getTimeslots().map(timeslot =>
          <option key={timeslot} value={timeslot}>{visitHelpers.formatTimeslot(timeslot, settings.dateFormats && settings.dateFormats.time)}</option>
        )}
      </select>
    );
  }
}
