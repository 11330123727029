import React from 'react';
import createReactClass from 'create-react-class';
import classNames from 'classnames';

import PropTypes from 'prop-types';

import {WurdText} from 'wurd-react';
import Markdown from '../../markdown';

var SpaceItem = createReactClass({

  propTypes: {
    price: PropTypes.string,
    onClick: PropTypes.func,
    title: PropTypes.string,
    desc: PropTypes.string,
    image: PropTypes.string,
    first: PropTypes.bool,
    buttonText: PropTypes.string.isRequired
  },

  render: function() {
    var props = this.props;

    return (
      <div className={classNames({
        'item-counter': true,
        'first': this.props.first
      })}>
        <div className="wrapper clearfix">
          <div className="img" style={{backgroundImage: `url(${props.image})`}}></div>

          <div className="info">
            <div className="desc">{props.title}</div>
            <div className="price">{props.price}</div>
            <div className="number-control">
              <button
                type="button"
                className={classNames("btn btn-primary btn-flat", {
                  'btn-success': props.isSelected
                })}
                onClick={props.onClick}
              >
                <WurdText id={props.buttonText} />
              </button>
            </div>
          </div>
        </div>

        <Markdown className="text-muted" style={{marginTop: 20}}>{props.desc}</Markdown>
      </div>
    );
  },

  renderButton(isSelected) {
    if (isSelected) {
      return (
        <button
          type="button"
          className="btn btn-primary"
          onClick={this.props.onSelect}
        >
          Select
        </button>
      );
    } else {
      return (
        <button
          type="button"
          className="btn btn-success"
          onClick={this.props.onSelect}
        >
          Selected
        </button>
      )
    }
  }

});

export default SpaceItem;
