import React from 'react';
import PropTypes from 'prop-types';

import { WurdText, WurdMarkdown } from 'wurd-react';
import BillingPlugin from '../../../plugins/billing';
import store from '../../../store';
import { fetchUserInfo } from '../../../actions';


function BillingStep({ onComplete }) {
  const { user } = store.get();

  function handleAddCard() {
    fetchUserInfo().then(() => onComplete());
  }

  if (!user) return null; // at this point user must be logged in

  return (
    <div className="schedule-step">
      <h2 className="sg-section-title"><WurdText id="orderFlow.billing.title" /></h2>

      <div className="content">
        <div className="form-horizontal">
          <WurdMarkdown id="orderFlow.billing.formIntro" style={{ marginBottom: 40 }} />

          <BillingPlugin onCardAdded={handleAddCard} view="form" user={user} />
        </div>
      </div>
    </div>
  );
}

BillingStep.propTypes = {
  onComplete: PropTypes.func.isRequired
};

export default BillingStep;
