import React from 'react';
import {WurdText} from 'wurd-react';
import BillingPlugin from '../../plugins/billing';


export default () => (
  <div className="schedule-page middle-block">
    <div className="middle-block__content">
      <section className="block-flat">
        <div className="header">
          <h3 className="sg-section-title"><WurdText id="billing.title" /></h3>
        </div>
        <div className="content">
          <BillingPlugin />
        </div>
      </section>
    </div>
  </div>
);
