import React from 'react';

import { datePeriod, price } from '../../utils/ui';

function InvoiceEntries({ invoice }) {
  return invoice.entries.map(entry => (
    <tr key={entry.id}>
      <td style={{ fontSize: '13px', lineHeight: '24px', width: '70%' }}>
        {entry.desc} <br />
        <span style={{ color: '#999' }}>
          {datePeriod(entry.date, entry.endDate).join(' - ')}
        </span>
      </td>
      <td style={{ fontSize: '13px', lineHeight: '24px', color: '#999', textAlign: 'center', width: '15%' }}>
        {entry.qty}{entry.qty && ' x '}{price(entry.amount)}
      </td>
      <td style={{ fontSize: '13px', lineHeight: '24px', color: '#999', textAlign: 'center', width: '15%' }}>
        &nbsp;{entry.tax !== 0 ? price(entry.tax) : null}
      </td>
      <td style={{ fontSize: '13px', lineHeight: '24px', textAlign: 'center', width: '15%' }}>
        &nbsp;{price(entry.total)}
      </td>
    </tr>
  ));
}

export default InvoiceEntries;
