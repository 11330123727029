import React from 'react';

function Field({icon='', hide='', children}) {
  return (
    <div className={`form-group ${hide ? 'hide' : ''}`}>
      <div className="input-group">
        <span className="input-group-addon"><i className={`fa fa-${icon}`}/></span>
        {children}
      </div>
    </div>
  );
}

export default Field;
