import React from 'react';
import wurd from 'wurd-react';
import PropTypes from 'prop-types';

function Input({
  type='text',
  component: Component = 'input',
  className='',
  placeholder,
  validate,
  onChange = () => {},
  ...props
}) {
  // if a validate prop is passed, we use HTMLInputElement.setCustomValidity DOM API
  const changeHandler = validate ? e => {
    const errMsg = validate(e);
    e.target.setCustomValidity(errMsg);
    onChange(e);
  } : onChange;
  return (
    <Component
      type={type}
      className={`form-control ${className}`}
      placeholder={placeholder && wurd.text(placeholder)}
      onChange={changeHandler}
      {...props}
    />
  )
}

Input.propTypes = {
  name: PropTypes.string.isRequired
};

export default Input;
