import React from 'react';
import PropTypes from 'prop-types';
import _ from 'underscore';

import * as itemHelpers from '../../../utils/item-helpers';
import { price as formatPrice } from '../../../utils/ui';
import wurd from 'wurd-react';

import Submit from '../../form/submit';
import store from '../../../store';
import OrderInfo from './order-info';
import Totals from './totals';

const cms = wurd.block('orderSummary');

function SummaryStep({ valetOrder: order, onComplete }) {
  const [errMsg, setErrMsg] = React.useState('');
  const [submitting, setSubmitting] = React.useState(false);

  function onSubmit(event) {
    if (event) event.preventDefault();
    setSubmitting(!submitting);

    onComplete();

    setSubmitting(!submitting);
  }

  const { settings, user } = store.get();

  const userPlanPrice = itemHelpers.getPlanPrice(user.planId, settings.plans);
  const orderPlanPrice = itemHelpers.getPlanPrice(order.planId, settings.plans);

  return (
    <div className="schedule-step">
      <h2 className="sg-section-title" ><cms.Text id="title" /></h2>
      <div className="content">
        {cms.text('intro.info') && (
          <cms.Markdown id="intro.info" className="alert alert-info" />
        )}

        {cms.text('intro.warning') && (
          <cms.Markdown id="intro.warning" className="alert alert-warning" />
        )}

        <OrderInfo cms={cms} order={order} settings={settings} />

        <table className="table">
          <thead>
            <tr>
              <th scope="col"></th>
              <th scope="col" style={{ textAlign: "right" }}><cms.Text id="quantity" /></th>
              <th scope="col" style={{ textAlign: "right" }}><cms.Text id="price" /></th>
              <th scope="col" style={{ textAlign: "right" }}><cms.Text id="subtotal" /></th>
            </tr>
          </thead>
          {order.planId && (
            <>
              <thead>
                <tr className="active">
                  <th scope="col"><cms.Text id="plan" /></th>
                  <th scope="col"></th>
                  <th scope="col"></th>
                  <th scope="col"></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td style={{ paddingRight: "42px" }}>{itemHelpers.getPlanTitle(order.planId, settings.plans)}</td>
                  <td style={{ textAlign: "right" }}>1</td>
                  <td style={{ textAlign: "right" }}>{formatPrice(orderPlanPrice)}</td>
                  <td style={{ textAlign: "right" }}>{formatPrice(orderPlanPrice)}</td>
                </tr>
              </tbody>
            </>
          )}

          {user.planId && (
            <>
              <thead>
                <tr className="active">
                  <th scope="col"><cms.Text id="plan" /></th>
                  <th scope="col"></th>
                  <th scope="col"></th>
                  <th scope="col"></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td style={{ paddingRight: "10px" }}>{itemHelpers.getPlanTitle(user.planId, settings.plans)}</td>
                  <td style={{ textAlign: "right" }}>1</td>
                  <td style={{ textAlign: "right" }}>{formatPrice(userPlanPrice)}</td>
                  <td style={{ textAlign: "right" }}>{formatPrice(userPlanPrice)}</td>
                </tr>
              </tbody>
            </>
          )}

          <thead>
            <tr className="active">
              <th scope="col-sm"><cms.Text id="items" /></th>
              <th scope="col"> </th>
              <th scope="col"> </th>
              <th scope="col"> </th>
            </tr>
          </thead>

          {_.map(order.boxCounts, (item, index) => {
            const price = itemHelpers.getTypePrice(index, settings.items);
            const boxCountsSubtotal = itemHelpers.getTypePrice(index, settings.items) * item;

            if (item === 0) {
              return null
            }

            return (
              <tbody key={index}>
                <tr>
                  <td>{itemHelpers.getTypeTitle(index, settings.items)}</td>
                  <td style={{ textAlign: "right" }}>{item}</td>
                  {price ?
                    <td style={{ textAlign: "right" }}>{formatPrice(price)}</td> :
                    <td style={{ textAlign: "right" }}> </td>
                  }
                  {price ? <td style={{ textAlign: "right" }}>{formatPrice(boxCountsSubtotal)}</td> :
                    <td style={{ textAlign: "right" }}> </td>}
                </tr>
              </tbody>
            );
          })}

          {_.map(order.bulkyCounts, (item, index) => {
            const price = itemHelpers.getTypePrice(index, settings.items);
            const bulkySubtotal = itemHelpers.getTypePrice(index, settings.items) * item;

            if (item === 0) {
              return null
            }

            return (
              <tbody key={index}>
                <tr>
                  <td>{itemHelpers.getTypeTitle(index, settings.items)}</td>
                  <td style={{ textAlign: "right" }}>{item}</td>
                  {price ? <td style={{ textAlign: "right" }}>{formatPrice(price)}</td> :
                    <td style={{ textAlign: "right" }}></td>}
                  {price ? <td style={{ textAlign: "right" }}>{formatPrice(bulkySubtotal)}</td>
                    : <td style={{ textAlign: "right" }}></td>}

                </tr>
              </tbody>
            );
          })}

          {order.storeItems && order.storeItems.length > 0 && (
            <>
              <thead>
                <tr className="active">
                  <th scope="col"><cms.Text id="products" /></th>
                  <th scope="col" className="table-light"> </th>
                  <th scope="col" className="table-light"> </th>
                  <th scope="col" className="table-light"> </th>
                </tr>
              </thead>
              {order.storeItems.map(store => {
                const price = itemHelpers.getProductPrice(store.id, settings.products)
                const storeSubtotal = itemHelpers.getProductPrice(store.id, settings.products) * store.quantity;

                return (
                  <tbody key={store.id}>
                    <tr>
                      <td>{itemHelpers.getProductTitle(store.id, settings.products)}</td>
                      <td style={{ textAlign: "right" }}>{store.quantity}</td>
                      <td style={{ textAlign: "right" }}>{formatPrice(price)}</td>
                      <td style={{ textAlign: "right" }}>{formatPrice(storeSubtotal)}</td>
                    </tr>
                  </tbody>
                );
              })}
            </>
          )}

        </table>

        <Totals cms={cms} settings={settings} order={order} user={user} />

        <p className="text-muted" style={{ textAlign: "center" }}><cms.Text id="footer" /></p>

        <Submit submitting={submitting} errMsg={errMsg} text="common.buttons.submit" onClick={onSubmit} />
      </div>
    </div>
  );
}


SummaryStep.propTypes = {
  valetOrder: PropTypes.object.isRequired,
  onComplete: PropTypes.func.isRequired
};


export default SummaryStep;
