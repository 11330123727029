import React from 'react';

import * as itemHelpers from '../../../utils/item-helpers';
import { price as formatPrice } from '../../../utils/ui';

const styles = {
  row: { marginTop: "0", paddingBottom: "15px", paddingRight: "15px" },
  textTotal: { fontWeight: "bold", fontSize: "20px" },
  text: { fontWeight: "bold", fontSize: "14px" }
}

export default function Totals({ cms, order, settings, user }) {
  const { subtotal, taxAmount, total } = itemHelpers.getTotals(order, user, settings);

  return (
    <>
      {settings.tax?.percent > 0 && (
        <>
          <div className="row" style={styles.row}>
            <div className="pull-right"><p style={styles.text}>
              <cms.Text id="subtotal" />: {formatPrice(subtotal)}</p></div>
          </div>

          <div className="row" style={styles.row}>
            <div className="pull-right">
              <p style={styles.text}>
                <cms.Text id="tax" /> ({settings.tax.percent}%): {formatPrice(taxAmount)}
              </p>
            </div>
          </div>
        </>
      )}

      <div className="row" style={styles.row}>
        <div className="pull-right">
          <p style={styles.textTotal}>
            <cms.Text id="total" />: {formatPrice(total)}
          </p>
        </div>
      </div>
    </>
  );
}
