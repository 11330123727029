import React from 'react';
import classNames from 'classnames';
import _ from 'underscore';

import { WurdText } from 'wurd-react';


function Submit({
  error, errMsg = typeof error === 'string' ? error : null, submitting, disabled,
  text = 'common.buttons.submit',
  buttonStyle = 'success',
  onClick,
  ...props
}) {
  return (
    <div className="submit-container" {...props}>
      {(errMsg || error) && (
        <div className="alert alert-danger">
          {/* <WurdText id="common.form.hasErrors" /> */}
          {errMsg || error.message}

          {error && (
            <ul>
              {_.map(error.errors, (err, k) =>
                <li key={k}>{k}: {err}</li>
              )}
            </ul>
          )}
        </div>
      )}

      <button
        className={`sg-btn btn btn-flat btn-block btn-${buttonStyle}`}
        type="submit"
        disabled={submitting || disabled}
        onClick={onClick}
        autoFocus={process.env.NODE_ENV !== 'production'}
      >
        <span className={classNames({ 'hide': submitting })}><WurdText id={text} /></span>
        <i className={classNames({
          'fa fa-spinner fa-spin': true,
          'hide': !submitting
        })}></i>
      </button>
    </div>
  );
}

export default Submit;
