import React, { useState, useEffect } from 'react';
import { HashRouter, Route, Redirect as RedirectRR, useLocation, Switch } from 'react-router-dom';

import * as authHelpers from './utils/auth-helpers';
import { fetchUserInfo } from './actions';
import store from './store';

import LoggedInLayout from './components/logged-in-layout';
import ItemsUserPage from './components/items-user-page';
import ItemsStoragePage from './components/items-storage-page';
import OrderPage from './components/schedule/order-page';
import SchedulePage from './components/schedule/schedule-page';
import Profile from './components/profile';
import Billing from './components/billing';
import Invoices from './components/invoices';

import LoggedOutLayout from './components/logged-out-layout';
import LoginPage from './components/auth/login-page';
import SignupPage from './components/auth/signup-page';
import ForgotPasswordPage from './components/auth/forgot-password-page';
import ResetPasswordPage from './components/auth/reset-password-page';

import ScheduleLayout from './components/schedule-layout';


// redirect, and possibly restore state before login
function Redirect({ to, redirectOnLogin }) {
  const location = useLocation();
  if (redirectOnLogin && !sessionStorage.getItem('redirectOnLogin')) {
    sessionStorage.setItem('redirectOnLogin', location.pathname);
  }

  return <RedirectRR to={to} />;
}

const Loader = () => (
  <div className="text-center text-muted" style={{ fontSize: 56, marginTop: 50 }}>
    <i className="fa fa-spinner fa-spin"></i>
  </div>
);

function LoggedInRoute({ component: Component, render = c => c, ...props }) {
  return (
    <Route
      {...props}
      render={() => <LoggedInLayout>{Component ? <Component /> : render()}</LoggedInLayout>}
    />
  );
}

function LoggedOutRoute({ component: Component, ...props }) {
  return (
    <Route
      {...props}
      render={() => <LoggedOutLayout><Component /></LoggedOutLayout>}
    />
  );
}


export default function Router() {
  const { user, settings } = store.get();

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!user) {
      fetchUserInfo()
        .catch(err => {
          if (err.status === 401) localStorage.removeItem('accessToken');
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [user]);

  if (loading) return <Loader />;

  return (
    <HashRouter>
      {user ? (
        <Switch>
          <LoggedOutRoute path="/get-started/:step?" component={OrderPage} />
          <LoggedInRoute path="/items-user" component={ItemsUserPage} />
          <LoggedInRoute path="/items-storage" component={ItemsStoragePage} />
          <LoggedInRoute path="/order-boxes/:step?" render={() => <ScheduleLayout><OrderPage /></ScheduleLayout>} />
          <LoggedInRoute path="/schedule/:step?" render={() => <ScheduleLayout><SchedulePage /></ScheduleLayout>} />
          <LoggedInRoute path="/profile" component={Profile} />
          <LoggedInRoute path="/billing" component={Billing} />
          <LoggedInRoute path="/invoices/:sid?" component={Invoices} />
          <LoggedInRoute path="/reschedule/:valetOrderSid/:step?" render={() => <ScheduleLayout><SchedulePage /></ScheduleLayout>} />

          {/* If new user, sent straight to order page */}
          <Redirect to={authHelpers.isNewUser() ? '/order-boxes' : '/items-user'} />
        </Switch>
      ) : (
        <Switch>
          <LoggedOutRoute path="/get-started/:step?" component={OrderPage} />
          <LoggedOutRoute path="/login" component={LoginPage} />
          <LoggedOutRoute path="/signup" component={SignupPage} />
          <LoggedOutRoute path="/forgot-password" component={ForgotPasswordPage} />
          <LoggedOutRoute path="/reset-password" component={ResetPasswordPage} />
          <Redirect to={settings.defaultPath || '/login'} redirectOnLogin />
        </Switch>
      )}
    </HashRouter>
  );
}
