import api, { getMarket } from '../utils/api';
import store from '../store';


export function fetchSettings() {
  const marketId = getMarket();
  return api.get('/v1/settings' + (marketId ? '?market=' + marketId : ''))
    .then(settings => {
      store.set({ settings });

      return settings;
    })
    .catch(e => {
      if (e.status !== 400) { // not a Market not found error, alert and exit
        return window.alert(e.message);
      }
      const url = new URL(window.location.href);
      url.searchParams.delete('market');
      url.pathname = '/';
      window.location.href = `${url}`; // clear up incorrect marketId in pathname or searchParams
    });
}
