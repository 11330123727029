import React from 'react';

import { datePeriod, price } from '../../utils/ui';

function InvoiceItems({ invoice }) {
  return invoice.items.map(item => (
    <tr key={item.id}>
      <td style={{ fontSize: '13px', lineHeight: '24px', width: '70%' }}>
        {item.desc}<br />
        <span style={{ color: '#999' }}>
          {datePeriod(item.startDate, item.endDate).join(' - ')}
        </span>
      </td>
      <td style={{ fontSize: '13px', lineHeight: '24px', color: '#999', textAlign: 'center', width: '15%' }}>
        {item.quantity} x {price(item.price)}
      </td>
      <td style={{ fontSize: '13px', lineHeight: '24px', color: '#999', textAlign: 'center', width: '15%' }}>
        &nbsp;{item.tax && item.tax !== 0 ? price(item.tax) : null}
      </td>
      <td style={{ fontSize: '13px', lineHeight: '24px', textAlign: 'center', width: '15%' }}>
        {price(item.total)}
      </td>
    </tr>
  ));
}

export default InvoiceItems;
