import React from 'react';

import Header from './header';
import SelectionBar from './selection-bar';
import Modal from './modal';
import store from '../store';


export default function LoggedInLayout({ children }) {
  const { user, error } = store.get();

  function closeError() {
    store.set({ error: null });
  }

  return (
    <div>
      <Header user={user} />

      <SelectionBar />

      <div id="cl-wrapper">
        {children}
      </div>

      <Modal show={!!error} onHide={closeError} cancelBtn={false} onOk={closeError}>{error}</Modal>
    </div>
  );
}
