import React from 'react';
import createReactClass from 'create-react-class';
import PropTypes from 'prop-types';

import {Modal as BSModal} from 'react-bootstrap';


var Modal = createReactClass({

  propTypes: {
    show: PropTypes.bool.isRequired,
    onHide: PropTypes.func.isRequired,
    onOk: PropTypes.func,
    title: PropTypes.string,
    okBtn: PropTypes.string,
    cancelBtn: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),     //Can be false (hide button), or text. If not passed default text will be used.
  },

  render: function() {
    var props = this.props;

    return (
      <BSModal show={props.show} onHide={props.onHide}>
        {props.title && 
          <div className="modal-header">{props.title}</div>  
        }

        <div className="modal-body">
          {this.props.children}
        </div>

        <div className="modal-footer">
          {props.cancelBtn !== false && 
            <button type="button" className="btn btn-default" onClick={props.onHide}>{props.cancelBtn || 'Cancel'}</button>
          }
          <button type="button" className="btn btn-primary" onClick={props.onOk}>{props.okBtn || 'OK'}</button>
        </div>
      </BSModal>
    );
  }

});

export default Modal;
