import React from 'react';
import moment from 'moment';
import { withRouter, Link } from 'react-router-dom';
import { price as formatPrice } from '../../utils/ui';
import wurd from 'wurd-react';
import { fetchInvoices } from '../../actions';

import store from '../../store';

import InvoiceDetails from './invoice-details';


const cms = wurd.block('invoices');

class InvoicesPage extends React.Component {
  state = {
    invoices: null,
    errMsg: null
  };

  componentDidMount() {
    this.fetchInvoices();
  }

  render() {
    const { invoices } = this.state;
    if (!invoices) return null; // not ready yet

    if (this.props.match.params.sid) {
      const invoice = invoices.find(o => o.sid === this.props.match.params.sid);

      return (
        <div className="schedule-step middle-block">
          <div className="middle-block__content">
            {invoice && (
              <ol className="breadcrumb">
                <li><Link to="/invoices"><cms.Text id="invoicesLink" /></Link></li>
                <li className="active">{invoice.sid.toUpperCase()}</li>
              </ol>
            )}

            <div className="block-flat">
              <InvoiceDetails invoice={invoice} />
            </div>
          </div>
        </div>
      );
    }

    return (
      <div className="schedule-page middle-block">
        <div className="middle-block__content">
          <section className="block-flat">
            <div className="header">
              <h3 className="sg-section-title"><cms.Text id="title" /></h3>
            </div>
            <div className="content">
              {invoices ? this.renderInvoices() : this.renderLoading()}
            </div>
          </section>
        </div>
      </div>
    );
  }

  renderLoading() {
    return (
      <div className="text-center" style={{ fontSize: '32px', margin: '20px' }}>
        <i className="loading fa fa-spinner fa-spin"></i>
      </div>
    );
  }

  renderInvoices() {
    const { invoices } = this.state;
    const { settings } = store.get();

    return (
      <table className="table table-striped">
        <thead>
          <tr>
            <th scope="col"><cms.Text id="number" /></th>
            <th scope="col"><cms.Text id="date" /></th>
            <th scope="col"><cms.Text id="amount" style={{ marginLeft: 10 }} /></th>
            <th scope="col"><cms.Text id="invoiceStatus" /></th>
          </tr>
        </thead>
        <tbody>
          {invoices.map(invoice => {
            return (
              <tr
                onClick={() => this.handleClicked(invoice)}
                key={invoice.sid}
                style={{ cursor: 'pointer' }}
              >
                <th scope="row" style={{ textTransform: 'uppercase' }}>
                  <Link to={`/invoices/${invoice.sid}`} style={{ color: 'inherit', display: 'inline-block', width: '100%' }}>{invoice.sid}</Link>
                </th>
                <td>{moment(invoice.startDate).format(settings.dateFormats.short)}</td>
                <td>
                  <span style={{ display: 'inline-block', width: 60, textAlign: 'right' }}>{formatPrice(invoice.total)}</span>
                </td>
                <td style={{ textTransform: 'uppercase' }}>
                  <cms.Text id={invoice.state} />
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  }

  handleClicked(invoice) {
    this.props.history.push(`/invoices/${invoice.sid}`);
  }

  fetchInvoices() {
    fetchInvoices().then(invoices => {
      this.setState({
        invoices: invoices.filter(invoice => invoice.state !== "draft")
      });
    });
  }
}

export default withRouter(InvoicesPage);
