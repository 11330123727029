import _ from 'underscore';

import * as language from './language';
import store from '../store';


export function addBulkyItem(item, itemState = 'user') {
  const newItem = {
    _isNew: true,
    id: Date.now(),
    state: itemState,
    type: item.type,
    sid: '',
    images: [],
    typeImage: item.image,
    typeTitle: item.title
  };

  const { items } = store.get();

  store.set({ items: [...items, newItem] });
}

export function upsertItem(item) {
  const { items } = store.get();

  const hasItem = items.some(o => o.id === item.id);

  if (!hasItem) {
    store.set({ items: [...items, item] });
  } else {
    store.set({ items: items.map(o => o.id === item.id ? { ...o, ...item } : o) });
  }
}

export function getItem(itemId) {
  const { items } = store.get();

  return items.find(item => item.id === itemId);
};

export function getItemsWithUser(items = store.get().items) {
  return items.filter(item => item.state === 'user');
};

export function getItemsInStorage(items = store.get().items) {
  return items.filter(item => item.state !== 'user');
};

export function getItemsSelectedToCollect() {
  const { selectedItems } = store.get();

  return selectedItems.filter(itemId => {
    const item = getItem(itemId);
    return item && item.state === 'user';
  });
}

export function getItemsSelectedToDeliver() {
  const { selectedItems } = store.get();

  return selectedItems.filter(itemId => {
    const item = getItem(itemId);
    return item && item.state !== 'user';
  });
}


/**
 * Get the display title for an item: the given title if available, or the default item name if not
 *
 * @param {Object} item
 * 
 * @return {String}
 */
export function getDisplayTitle(item) {
  if (item.title) {
    return item.title;
  }
  else if (item.opsTitle) {
    return item.opsTitle;
  } else {
    return item.typeTitle && item.typeTitle[language.get()];
  }
};

/**
 * Get the title of the item type; i.e. the correct language description for the item type,
 * not the customer's custom title
 *
 * @param {String} type
 * @return {String}
 */
export function getTypeTitle(type, items) {
  var itemConfig = _.findWhere(items, { type });
  if (!itemConfig || !itemConfig.title) return type;

  //return itemConfig.title[lang] || type;
  return getLangValue(itemConfig, 'title');
};

export function getTypePrice(type, items) {
  var itemConfig = _.findWhere(items, { type });
  return itemConfig && itemConfig.price;
};

/**
 * Get the value of an item config setting, for the selected language
 *
 * @param {Object} settings   Item settings
 * @param {String} prop       Property to get from item config
 * @return {String}
 */
export function getLangValue(settings, prop) {
  const lang = language.get();
  if (typeof settings[prop] === 'string') return settings[prop];
  return settings[prop]?.[lang] || Object.values(settings[prop] || {}).find(Boolean) || '';
};


/**
 * Get the (optional) description of the item type as defined in business config 'item' settings
 *
 * @param {String} type
 * @return {String}
 */
export function getTypeDesc(type, items) {
  var itemConfig = _.findWhere(items, { type });
  if (!itemConfig || !itemConfig.desc) return null;

  //return itemConfig.desc[lang] || null;
  return getLangValue(itemConfig, 'info');
};


/**
 * Returns the associated valetOrder that the item is being delivered/collected in, if any
 */
export function getOrder(item) {
  const { valetOrders } = store.get();

  //If explicitly set on the item use that
  if (item.valetOrderId) {
    return _.find(valetOrders, order => order.id === item.valetOrderId);
  }

  //Otherwise the item may have been ordered but not processed yet so check the order
  const itemId = item.id;
  const listName = (item.state === 'user') ? 'collectItems' : 'deliverItems';

  return _.find(valetOrders, order => _.contains(order[listName], itemId));
};


/**
 * Returns the config settings for the business's bulky items
 *
 * @return {Object[]}
 */
export function getBulkyItemConfigs(items = []) {
  return items.filter(item => item.type.substr(0, 5) === 'bulky');
};

/**
* Return name of Products
*/

export function getProductTitle(productId, products) {
  var productConfig = _.findWhere(products, { id: productId });
  if (!productConfig || !productConfig.title) return productId;

  return getLangValue(productConfig, 'title');
};
/**
* Return display price of Products
*/

export function getProductDisplayPrice(productId, products) {
  var productConfig = _.findWhere(products, { id: productId });
  if (!productConfig || !productConfig.displayPrice) return productId;

  return getLangValue(productConfig, 'displayPrice');
};


/**
* Return price of Products
*/
export function getProductPrice(productId, products) {
  var productConfig = _.findWhere(products, { id: productId });

  return productConfig && productConfig.price;
};

/**
* Return name of plan
*/
export function getPlanTitle(planId, plans) {
  var planConfig = _.findWhere(plans, { id: planId });
  if (!planConfig || !planConfig.title) return planId;

  return getLangValue(planConfig, 'title')
}
/**
* Return display Price of plan
*/
export function getPlanDisplayPrice(planId, plans) {
  var planConfig = _.findWhere(plans, { id: planId });
  if (!planConfig || !planConfig.title) return planId;

  return getLangValue(planConfig, 'displayPrice')
}

/**
* Return Price of plan
*/
export function getPlanPrice(planId, plans) {
  var planConfig = _.findWhere(plans, { id: planId });

  return planConfig && planConfig.price;
};

/** 
 * Return prices of valet-order
 */
export function getTotals(order, user, settings) {
  let subtotal = 0;
  subtotal += user.planId ? getPlanPrice(user.planId, settings.plans) : 0;
  subtotal += order.planId ? getPlanPrice(order.planId, settings.plans) : 0;

  _.each(order.boxCounts, (item, index) => {
    subtotal += getTypePrice(index, settings.items) * item;
  });
  _.each(order.bulkyCounts, (item, index) => {
    subtotal += getTypePrice(index, settings.items) * item;
  });
  _.each(order.storeItems, store => {
    subtotal += getProductPrice(store.id, settings.products) * store.quantity;
  });

  const taxAmount = subtotal * ((settings.tax?.percent || 0) / 100);
  const total = subtotal + taxAmount;

  return { subtotal, taxAmount, total };
}
