import React from 'react';
import store from '../store';

const DynamicLoader = (Component) => (props) => (
  <React.Suspense fallback={<i className="loading fa fa-spinner fa-spin"></i>}>
    <Component {...props} />
  </React.Suspense>
);

const LazyStripePlugin = DynamicLoader(React.lazy(() => import('./stripe')));
const LazyGmopgPlugin = DynamicLoader(React.lazy(() => import('./gmopg')));
const LazyBraintreePlugin = DynamicLoader(React.lazy(() => import('./braintree')));

export default props => {
  const { settings, user } = store.get();

  switch (settings.billing) {
    case 'braintree':
      return <LazyBraintreePlugin user={user} {...props} />;

    case 'stripe':
      return <LazyStripePlugin user={user} {...props} />;

    case 'gmopg':
      return <LazyGmopgPlugin user={user} {...props} />;

    default:
      return <div>Invalid payment method: {settings.billing}</div>;
  }
};
