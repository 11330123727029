import React from 'react';
import createReactClass from 'create-react-class';
import PropTypes from 'prop-types';
import _ from 'underscore';
import { WurdText, WurdMarkdown } from 'wurd-react';

import * as itemHelpers from '../../../utils/item-helpers';
import { stepsGetError } from '../../../utils/order-helpers';

import Item from './item';
import Submit from '../../form/submit';


var PlanStep = createReactClass({

  propTypes: {
    settings: PropTypes.object.isRequired,
    onComplete: PropTypes.func.isRequired
  },

  getInitialState: function () {
    var state = {
      selected: null,

      errMsg: '',
      submitting: false
    }

    return state;
  },

  render: function () {
    const { selected, errMsg, submitting } = this.state;
    var { settings } = this.props;

    return (
      <div className="schedule-step">
        <h2 className="sg-section-title"><WurdText id="orderPlans.title" /></h2>

        <div className="content">
          <WurdMarkdown id="orderPlans.intro" />

          {_.map(settings.plans, (item, index) => {
            var getLang = itemHelpers.getLangValue.bind(null, item);
            var isSelected = selected === item.id;

            return (
              <Item
                key={item.id}
                price={getLang('displayPrice')}
                onClick={this.onSelect.bind(null, item.id)}
                title={getLang('title')}
                desc={getLang('info')}
                image={item.image}
                first={index === 0}
                isSelected={isSelected}
                buttonText={isSelected ? 'orderPlans.selected' : 'orderPlans.select'}
              />
            );
          })}

          <Submit
            submitting={submitting}
            errMsg={errMsg}
            text="common.buttons.next"
            disabled={!selected}
            onClick={this.onSubmit}
          />
        </div>
      </div>
    );
  },

  onSubmit: function (event) {
    if (event) event.preventDefault();

    const { selected } = this.state;

    const errMsg = stepsGetError.plan({ planId: selected });
    if (errMsg) {
      return this.setState({ errMsg });
    }

    this.props.onComplete({ planId: this.state.selected });
  },

  /**
   * Updates the state (count) for the given item counter
   *
   * @param {String} id
   */
  onSelect: function (id) {
    this.setState({
      selected: id
    });
  }
});

export default PlanStep;
