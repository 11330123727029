import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import wurd from 'wurd-react';

import DatePicker from 'react-datepicker';
import './date-picker.scss';


function AppDatePicker({ onChange, value, timeslots = [], className, ...props }) {
  const dateFormat = wurd.text('timeForm.date.dateFormat') || 'YYYY-MM-DD';

  return (
    <DatePicker
      autoComplete="off"
      className={`form-control ${className}`}
      placeholderText={wurd.text('timeForm.date.placeholder')}
      selected={value && moment(value)}
      onChange={(date) => {
        onChange(date.format('YYYY-MM-DD'));
      }}
      includeDates={timeslots.map(item => moment(item.date))}
      dateFormat={dateFormat}
      withPortal={window.innerWidth < 600}
      {...props}
    />
  );
}

AppDatePicker.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string // yyyy-MM-dd format
}

export default AppDatePicker;
