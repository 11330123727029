import * as libs from './third-parties';
import wurd from 'wurd-react';

let segment;
let googleAnalytics;
let facebookPixel;

function log() {
  if (process.env.NODE_ENV !== 'development') return;

  console.info('analytics', ...arguments);
}


export default {
  init(settings) {
    log('init', settings);

    //if (process.env.NODE_ENV !== 'production') return;

    if (settings.segment?.id) {
      libs.segment(settings.segment.id);
      segment = window.analytics;
    }

    if (settings.gtm?.id) {
      log('gtm', settings.gtm.id);
      libs.gtm(settings.gtm.id);
    }

    if (settings.ga?.id) {
      libs.ga(settings.ga.id);
      googleAnalytics = window.ga;
    }

    if (settings.fbq?.id) {
      log('fbq', settings.fbq.id);
      libs.fbq(settings.fbq.id);
      facebookPixel = window.fbq;
    }

    if (settings.olark?.id && !wurd.editMode) { // don't show in editMode, it's overlapping wurd publish button
      libs.olark(settings.olark.id);
    }
  },

  page(name) {
    log('page', name);

    if (segment) {
      window.analytics.page(name);
    }

    if (googleAnalytics) {
      window.ga('send', 'pageview');
    }
  },

  identify(user) {
    log('identify', user);

    if (segment) {
      window.analytics.identify(user.id, {});
    }
  },

  track(categoryName, eventName, info = {}) {
    log('track', categoryName, eventName, info);

    if (segment) {
      window.analytics.track(eventName);
    }

    if (googleAnalytics) {
      window.ga('send', {
        hitType: 'event',
        eventCategory: categoryName,
        eventAction: eventName,
      });
    }
  },

  signedUp(user) {
    log('signedUp', user);
    
    this.identify(user);

    if (segment) {
      window.analytics.track('Signed Up', {
        userId: user.id,
      });
    }

    if (googleAnalytics) {
      window.ga('send', {
        hitType: 'event',
        eventCategory: 'get started',
        eventAction: 'signup',
      });
    }

    if (facebookPixel) {
      window.fbq('track', 'Lead');
    }
  },

  createdValetOrder(valetOrder) {
    log('createdValetOrder', valetOrder);

    if (segment) {
      window.analytics.track('Created Valet Order');
    }

    if (googleAnalytics) {
      window.ga('send', {
        hitType: 'event',
        eventCategory: 'get started',
        eventAction: 'order boxes',
      });
    }

    if (facebookPixel) {
      window.fbq('track', 'CompleteRegistration');
    }
  },
};
