import React from 'react';
import createReactClass from 'create-react-class';
import classNames from 'classnames';

import PropTypes from 'prop-types';

import Markdown from '../markdown';

var ScheduleItemCounter = createReactClass({

  propTypes: {
    price: PropTypes.string,
    count: PropTypes.number,
    maxCount: PropTypes.number,
    onChange: PropTypes.func,
    title: PropTypes.string,
    desc: PropTypes.string,
    image: PropTypes.string,
    first: PropTypes.bool
  },

  getDefaultProps: function() {
    return {
      minCount: 0,
      maxCount: 30
    };
  },

  render: function() {
    var props = this.props;

    return (
      <div className={classNames({
        'item-counter': true,
        'first': this.props.first
      })}>
        <div className="wrapper clearfix">
          <div className="img" style={{backgroundImage: `url(${props.image})`}}></div>

          <div className="info">
            <div className="desc">{props.title}</div>
            <div className="price">{props.price}</div>
            <div className="number-control">
              <button 
                type="button" 
                className="btn btn-default btn-flat" 
                onClick={this.removeItem}>
                <i className="fa fa-minus"></i>
              </button><span className="number">{props.count}</span><button 
                type="button" 
                className="btn btn-default btn-flat" 
                onClick={this.addItem}>
                <i className="fa fa-plus"></i>
              </button>
            </div>
          </div>
        </div>
          
        <Markdown className="text-muted" style={{marginTop: 20}}>{props.desc}</Markdown>
      </div>
    );
  },

  removeItem: function() {
    var props = this.props;

    if (props.count > props.minCount) {
      props.onChange(props.count - 1);
    }
  },

  addItem: function() {
    var props = this.props;

    if (props.count < props.maxCount) {
      props.onChange(props.count + 1);
    }
  }

});

export default ScheduleItemCounter;
