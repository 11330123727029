import React from 'react';
import markdown from 'marked';

function Markdown({children: content, ...props}) {
  //Prevent error from markdown if content is undefined
  if (!content) return null;

  return (
    <div 
      {...props}
      dangerouslySetInnerHTML={{__html: markdown(content) }} 
    />
  )
}

export default Markdown;
