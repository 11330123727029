import React from 'react';
import createReactClass from 'create-react-class';
import { withRouter } from "react-router-dom";
import PropTypes from 'prop-types';

import { signin } from '../../actions';
import wurd from 'wurd-react';

import { Form, Field, Input } from '../form';


var LoginForm = createReactClass({
  getInitialState() {
    return {
      isSubmitting: false,
      errMsg: null
    };
  },

  async onSubmit({ email, password }) {
    this.setState({ isSubmitting: true });

    try {
      await signin(email, password);

      this.setState({
        errMsg: null,
        isSubmitting: false
      });

      this.props.onComplete();
    } catch (err) {
      this.setState({
        isSubmitting: false,
        errMsg: err.status === 401 ? wurd.text('login.errors.invalid') : err.message
      });
    }
  },

  render() {
    var state = this.state;

    return (
      <Form
        submitText="login.submit"
        onSubmit={this.onSubmit}
        isSubmitting={state.isSubmitting}
        errMsg={state.errMsg}
      >
        <Field icon="envelope">
          <Input
            placeholder="login.email"
            type="email"
            name="email"
            required
            autoComplete="username"
            autoFocus
          />
        </Field>

        <Field icon="lock">
          <Input
            placeholder="login.password"
            type="password"
            name="password"
            autoComplete="current-password"
            required
          />
        </Field>
      </Form>
    );
  }
});

LoginForm.propTypes = {
  onComplete: PropTypes.func.isRequired
};

export default withRouter(LoginForm);
