/**
 * General UI helpers, including choosing phonegap or browser-specific functions
 */
import _ from 'underscore';
import wurd from 'wurd-react';
import moment from 'moment';

import store from '../store';


/**
 * Detects if in phonegap
 *
 * @return {Boolean}
 */
export const isPhonegap = function () {
  return !!navigator.userAgent.match(/(iPhone|iPod|iPad|Android|BlackBerry|IEMobile)/);
};

export const isBrowser = function () {
  return !isPhonegap();
};

export const isMobile = function () {
  return navigator.userAgent.match(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i);
};

export const isDesktop = function () {
  return !isMobile();
};


/**
 * Creates an alert dialog, using native dialogs in Phonegap
 *
 * @param {String} message
 * @param {Object} [options]
 * @param {String} [options.title]
 * @param {String} [options.button]     OK button text
 * @param {Function} cb                 Callback
 */
export const alert = function (message, { title, button = wurd.text('common:buttons.ok') } = {}, cb) {
  //Phonegap
  if (navigator.notification) {
    navigator.notification.alert(message, cb, title, button);
  }

  //Browser
  else {
    window.alert(message);
    if (cb) cb();
  }
};


/**
 * Creates a confirm dialog, using native dialogs in Phonegap*
 *
 * @param {String} message
 * @param {Object} [options]
 * @param {String} [options.title]      Dialog title
 * @param {String[]} [options.buttons]  Button labels (['OK', 'Cancel'])
 * @param {Function} cb                 Callback({Boolean} result)
 */
export const confirm = function (message, options, cb) {
  //Normalise callbacks
  if (arguments.length === 2) { //message, cb
    cb = options;
    options = {};
  } else if (arguments.length === 1) { //message
    options = {};
    cb = _.noop;
  }

  //Defaults
  options = _.extend({
    title: wurd.text('common:dialogs.confirm'),
    buttons: [wurd.text('common:buttons.ok'), wurd.text('common:buttons.cancel')]
  }, options);

  //Phonegap
  if (navigator.notification) {
    navigator.notification.confirm(message, function (clickedIndex) {
      var result = (clickedIndex === 1); //OK
      cb(result);
    }, options.title, options.buttons);
  }

  //Browser
  else {
    var result = window.confirm(message);
    cb(result);
  }
};


export function datePeriod(startDate, endDate, format = 'short') {
  const { settings, lang = 'en' } = store.get();
  const momentFormat = settings.dateFormats[format];

  const startMoment = moment(startDate).locale(lang);
  const result = [startMoment.format(momentFormat)];

  if (endDate) {
    const endMoment = moment(endDate).locale(lang);

    if (endMoment.month() !== startMoment.month() && endMoment.date() === startMoment.date()) {
      endMoment.add(-1, 'days');
    }

    result.push(endMoment.format(momentFormat));
  }

  return result;
}

/**
 * Format a price with 2 decimal places
 * 
 * @param {String|Number} amount
 *
 * @return {String}
 * 
 * @todo use const nf = new Intl.NumberFormat('en', {style: 'currency', currency: 'USD'}); nf.format(price)
 */
export function price(amount) {
  const { currency, defaultLang } = store.get('settings');

  // Some currencies such as Yen don't use decimals
  const fractionDigits = ['￥'].includes(currency) ? 0 : 2;

  const formattedAmount = new Intl.NumberFormat(defaultLang, {
    minimumFractionDigits: fractionDigits,
    maximumFractionDigits: fractionDigits,
  }).format(amount);

  return currency + formattedAmount;
}


/**
 * Formats a date
 *
 * @param {String} dateStr    In format YYYY-MM-DD
 * @param {String} format     Can be a predefined format e.g. short/long, or a pattern for moment, e.g. YYYY-MM-DD
 */
export function date(dateStr, format = 'long') {
  const { settings } = store.get();

  const pattern = settings.dateFormats[format] || format;

  return moment(dateStr).format(pattern);
}
