/**
 * Modal content for editing an item
 * NOTE This component renders and behaves differently on desktop and mobile 
 * in an attempt to make modals work better on mobile devices
 */
'use-strict';

import React from 'react';
import createReactClass from 'create-react-class';
import * as ui from '../utils/ui';
import wurd, { WurdText } from 'wurd-react';

import { updateItem, deleteItemImage, deleteBulkyItem } from '../actions';
import * as itemHelpers from '../utils/item-helpers';

import { DropdownButton, MenuItem } from 'react-bootstrap';


var ItemEditor = createReactClass({
  getDefaultProps() {
    return {
      item: {},
      onRequestClose() { }
    };
  },

  getInitialState() {
    var item = this.props.item;

    return {
      title: item.title || '',
      desc: item.desc || '',
      errMsg: ''
    };
  },

  render() {
    const { className, onRequestClose, style } = this.props;
    const menuItems = this.renderMenuItems();

    return (
      <div className={'item-editor ' + className} style={style}>
        <div className="modal-body">
          <form name="form" onSubmit={this.onSubmit}>
            <div className="form-group">
              <label><WurdText id="items.editor.title.label" /></label>
              <input
                className="form-control"
                name="title"
                type="text"
                placeholder={wurd.text('items.editor.title.placeholder')}
                value={this.state.title}
                onChange={e => this.setState({ title: e.target.value })}
              />
            </div>

            <div className="form-group">
              <label><WurdText id="items.editor.desc.label" /></label>
              <textarea
                className="form-control"
                name="desc"
                placeholder={wurd.text('items.editor.desc.label')}
                value={this.state.desc}
                onChange={e => this.setState({ desc: e.target.value })}
              >
              </textarea>
            </div>

          </form>
        </div>
        <div className="modal-footer" style={{ textAlign: 'initial', display: 'flex' }}>
          {(menuItems.length > 0) &&
            <DropdownButton style={{ flex: 1 }} id="itemDropdown" title={<i className="fa fa-trash"></i>} tabIndex="-1">
              {menuItems}
            </DropdownButton>
          }

          <button onClick={onRequestClose} type="button" className="btn btn-default">
            <WurdText id="common.buttons.cancel" />
          </button>

          <button onClick={this.onSubmit} type="button" className="btn btn-primary">
            <WurdText id="common.buttons.save" />
          </button>
        </div>
      </div>
    );
  },

  renderMenuItems() {
    const { item } = this.props;
    const associatedVisit = itemHelpers.getOrder(item);

    const MenuItems = [];

    //Delete image
    if (item.images && item.images.length) {
      MenuItems.push(<MenuItem onSelect={this.deleteImage} key="image"><i className="fa fa-camera"></i> <WurdText id="items.editor.deleteImage" /></MenuItem>);
    }

    //Delete item
    if (
      item.type.substr(0, 5) === 'bulky'
      && item.state === 'user'
      && !associatedVisit
    ) {
      MenuItems.push(<MenuItem onSelect={this.deleteItem} key="item"><i className="fa fa-trash"></i> <WurdText id="items.editor.deleteItem" /></MenuItem>)
    }

    return MenuItems;
  },

  onSubmit(event) {
    event.preventDefault();

    const { item } = this.props;
    const { title, desc } = this.state;

    if (item._isNew && !title) {
      ui.alert(wurd.text('items.editor.errors.noTitle'), { title: this.props.settings.brand && this.props.settings.brand.name });
      return;
    }

    updateItem(item.id, { title, desc });

    //Close modal
    this.props.onRequestClose();
  },

  deleteImage() {
    ui.confirm(wurd.text('items.editor.confirmDeleteImage'), confirmed => {
      if (!confirmed) return;

      const { item } = this.props;
      const image = item.images && item.images[0];
      if (!image) return;

      deleteItemImage(item.id, image.id);

      //Close editor
      this.props.onRequestClose();
    });
  },

  deleteItem() {
    ui.confirm(wurd.text('items.editor.confirmDeleteItem'), confirmed => {
      if (!confirmed) return;

      const { item } = this.props;

      deleteBulkyItem(item.id);

      //Close editor
      this.props.onRequestClose();
    });
  }
});

export default ItemEditor;
