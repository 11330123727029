import React from 'react';

import * as itemHelpers from '../../../utils/item-helpers';

/**
 * 
 * @param {Object} props
 * @param {Object} props.item
 */
export default function ItemOption({ item, checked, onChange }) {
  const itemImage = item.images?.[0]?.url || item.opsImages?.[0]?.url || item.typeImage;

  const handleChange = e => onChange(e.target.checked);

  return (
    <label
      style={{ fontWeight: 'initial', marginBottom: 'initial', display: 'grid', gridTemplateColumns: 'auto auto minmax(80px, max-content) 1fr', gridColumnsGap: 8, alignItems: 'center' }}
    >
      <input type="checkbox" style={{ width: 18, height: 18 }} checked={checked} onChange={handleChange} />
      <img src={itemImage} style={{ width: 80, height: 60, objectFit: 'contain', margin: '0 8px' }} />
      <span className="text-muted">{`#${(item.sid || '').toUpperCase()}`}</span>
      <span>{itemHelpers.getDisplayTitle(item)}</span>
    </label>
  );
}
