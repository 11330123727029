import React from 'react';
import _ from 'underscore';
import { Link } from 'react-router-dom';
import { DropdownButton, MenuItem } from 'react-bootstrap';
import { WurdText } from 'wurd-react';

import * as itemHelpers from '../utils/item-helpers';
import * as visitHelpers from '../utils/visit-helpers';
import * as ui from '../utils/ui';
import store from '../store';

import ItemList from './item-list.react';
import NextVisit from './items-next-visit.react';
import ItemsUserEmpty from './items-user-empty.react';


export default function ItemsUserPage() {
  const { settings, selectedItems, valetOrders } = store.get();

  const itemsWithUser = itemHelpers.getItemsWithUser();
  const itemsInStorage = itemHelpers.getItemsInStorage();
  const nextDelivery = visitHelpers.getNextDelivery(valetOrders);

  // Group items by order/delivery date (if any)
  const itemsByOrder = _.groupBy(itemsWithUser, item => itemHelpers.getOrder(item)?.id);

  const ordersWithItems = valetOrders.map((order) => {
    const items = itemsByOrder[order.id];
    if (!items || !items.length) return null;

    return {
      order,
      items,
    };
  }).filter(Boolean);

  const itemsWithoutOrder = itemsByOrder[undefined] || null;

  function renderBulkyButton() {
    const bulkyItems = itemHelpers.getBulkyItemConfigs(settings.items);

    if (!bulkyItems.length) return null;

    if (bulkyItems.length === 1) {
      var item = bulkyItems[0];
      // 
      return (
        <div className="text-center">
          <button
            className="sg-btn add-item-btn btn btn-success"
            onClick={() => itemHelpers.addBulkyItem(item, 'user')}
          ><i className="fa fa-plus"></i> <WurdText id="items.withUser.addBulky" /></button>
        </div>
      )
    }

    // Multiple bulky items
    else {
      return (
        <div className="text-center">
          <DropdownButton
            id="bulkyDropdown"
            bsStyle="success"
            dropup
            noCaret
            title={<span><i className="fa fa-plus"></i> <WurdText id="items.withUser.addBulky" /></span>}
          >
            {bulkyItems.map(item =>
              <MenuItem key={item.type} onSelect={() => itemHelpers.addBulkyItem(item, 'user')}>
                {itemHelpers.getTypeTitle(item.type, settings.items)}
              </MenuItem>
            )}
          </DropdownButton>
        </div>
      )
    }
  }

  return (
    <div>
      <div className="page-head page-head-small">
        <h3 className="sg-page-title"><WurdText id="items.withUser.title" /></h3>
      </div>

      <div className="cl-mcont">
        {valetOrders.map(order => (
          <NextVisit visit={order} key={order.id} />
        ))}

        {ordersWithItems.map(({ order, items }) => (
          <div key={order.id} style={{ margin: '40px 0' }}>
            <h4><WurdText id="items.withUser.orderGroup.title" vars={{ date: ui.date(order.collectDate || order.date) }} /></h4>
            <ItemList items={items} selectedItems={selectedItems} />
          </div>
        ))}


        {itemsWithoutOrder && (
          <>
            <h4><WurdText id="items.withUser.orderGroup.others" /></h4>
            <ItemList items={itemsWithoutOrder} selectedItems={selectedItems} />
          </>
        )}

        {itemsWithUser.length === 0 && <ItemsUserEmpty itemsInStorage={itemsInStorage} nextDelivery={nextDelivery} />}

        {renderBulkyButton()}

        {itemsInStorage.length > 0 && (
          <div className="item-state-switcher">
            <Link to="/items-storage"><WurdText id="items.withUser.viewInStorage" /></Link>
          </div>
        )}
      </div>
    </div>
  );
}
