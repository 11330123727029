import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import wurd, { WurdText } from 'wurd-react';
import * as itemHelpers from '../../../utils/item-helpers';
import { Submit } from '../../form';

import ItemOption from './item-option';


/**
 * @param {object} props
 * @param {'collect'|'deliver'} props.kind 
 */
export default function ItemsStep({ onComplete, valetOrder, kind, isSubmitting, errMsg }) {
  const initialItems = valetOrder[`${kind}Items`] || [];
  const [items, setItems] = useState(initialItems);

  useEffect(() => {
    setItems(initialItems);
  }, [kind, valetOrder]);

  const availItems = kind === 'collect' ? itemHelpers.getItemsWithUser() : itemHelpers.getItemsInStorage();

  availItems.sort((a, b) => initialItems.includes(b.id) - initialItems.includes(a.id));

  function onSubmit() {
    onComplete({
      [`${kind}Items`]: items,
    })
  }

  let { date = '', timeslot = '' } = valetOrder;
  if (kind === 'collect' && valetOrder.collect === 'later' && valetOrder.collectDate && valetOrder.collectTimeslot) {
    date = valetOrder.collectDate;
    timeslot = valetOrder.collectTimeslot;
  }

  return (
    <div className="schedule-step">
      <h2 className="sg-section-title">
        <WurdText
          id={`scheduleFlow.${kind}Items.title`}
          vars={{
            date: moment(date).format(wurd.text('items.button.dateFormat')),
            timeslot: timeslot.replace(/(\d\d)(\d\d)/g, '$1:$2'),
          }}
        />
      </h2>

      <div className="content">
        <ul className="list-unstyled">
          {availItems.map(item => {
            const inputProps = {
              checked: items.includes(item.id),
              onChange: checked => setItems(a => checked ? [...a, item.id] : a.filter(id => id !== item.id)),
            };
            return (
              <li key={item.id}><ItemOption item={item} {...inputProps} /></li>
            );
          })}
        </ul>
      </div>

      <div className="content clearfix">
        <div className="footer">
          <Submit
            submitting={isSubmitting}
            errMsg={errMsg}
            text="common.buttons.next"
            onClick={onSubmit}
          />
        </div>
      </div>
    </div>
  );
}

ItemsStep.propTypes = {
  onComplete: PropTypes.func.isRequired
};
