/**
 * For users who have no items and have not yet ordered boxes
 */

import React from 'react';
import createReactClass from 'create-react-class';
import {Link} from 'react-router-dom';
import wurd, {WurdText} from 'wurd-react';


var ItemsGetStarted = createReactClass({
  
  render: function() {
    return (
      <div>
        {wurd.map('items.noResults', (item, i) => <p key={i}>{item.text()}</p>)}

        <Link className="sg-btn action-btn btn btn-success btn-lg" to="/order-boxes">
          <i className="fa fa-archive"></i> <WurdText id="common.buttons.orderBoxes" />
        </Link>
      </div>
    );
  }
  
});

export default ItemsGetStarted;
