import React from 'react';
import PropTypes from 'prop-types';

function Select({className='', children, ...props}) {
  return (
    <select className={`form-control ${className}`} {...props}>
      {children}
    </select>
  );
}

Select.propTypes = {
  name: PropTypes.string.isRequired
};

export default Select;
