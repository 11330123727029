import React from 'react';
import wurd from 'wurd-react';
import moment from 'moment';

import store from '../../store';
import { price } from '../../utils/ui';

import InvoiceItems from './invoice-items';
import InvoiceEntries from './invoice-entries';
import InvoiceHeader from './invoice-header';

const cms = wurd.block('invoiceDetails');

const invoiceStyle = {
  paid: { color: '#60C060', fontWeight: 'bold', fontSize: 16 },
  failed: { color: '#b94a48', fontWeight: 'bold', fontSize: 16 },
};

function renderInvoiceState(state) {
  if (state === 'sent') {
    return null;
  }
  return (
    <strong style={invoiceStyle[state]}>
      <cms.Text id={state} />
    </strong>
  );
}

function InvoiceDetails({ invoice }) {
  const { settings } = store.get();

  if (!invoice) {
    return <p><cms.Text id="notFound" /></p>;
  }

  return (
    <>
      <div className="container-fluid">
        <table width="100%">
          <tbody>
            <tr>
              <td
                width="50%" style={{
                  fontSize: 16,
                  textAlign: 'left',
                  lineHeight: '24px',
                  verticalAlign: 'top',
                  textTransform: 'uppercase',
                }}
              >
                <cms.Text id="sid" type="strong" />{' '}#{invoice.sid}
                <br />
                {renderInvoiceState(invoice.state)}
              </td>
              <td
                width="50%"
                style={{
                  fontSize: 13,
                  textAlign: 'right',
                  lineHeight: '24px',
                  verticalAlign: 'top',
                }}
              >
                <cms.Text id="date" />{' '}{moment(invoice.startDate).format(settings.dateFormats.short)}
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div className="col-sm-12" style={{ color: '#2494f2', marginTop: 40, fontSize: 16 }}>
        <cms.Text id="yourOrder" />
      </div>

      <div className="col-sm-12">
        <InvoiceHeader />

        <tbody>
          <InvoiceItems invoice={invoice} settings={settings} />
          <InvoiceEntries invoice={invoice} settings={settings} />
        </tbody>
      </div>

      <div className="row" style={{ marginTop: 20 }}>
        <div className="col-sm-4" style={{ float: 'right' }}>
          <h5 style={{ float: 'right' }}>
            <cms.Text id="subtotal" />{' '}{price(invoice.subtotal)}
          </h5>
        </div>
      </div>
      {
        !invoice.tax ? null : (
          <div className="row">
            <div className="col-sm-4" style={{ float: 'right' }}>
              <h5 style={{ float: 'right' }}>
                <cms.Text id="tax" />:{' '}{price(invoice.tax)}
              </h5>
            </div>
          </div>
        )
      }
      <div className="row" style={{ marginTop: 20 }}>
        <div className="col-sm-4" style={{ float: 'right' }}>
          <h4
            style={{
              float: 'right',
              fontWeight: 'bold',
              textTransform: 'uppercase',
              whiteSpace: 'nowrap',
            }}
          >
            <cms.Text id="total" /> {price(invoice.total)}
          </h4>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-4" style={{ float: 'right' }}>
          <h4 style={{ float: 'right', whiteSpace: 'nowrap' }}>
            <cms.Text id="amountPaid" />{' '}{price(invoice.amountPaid)}
          </h4>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-4" style={{ float: 'right' }}>
          <h4
            style={{
              float: 'right',
              fontWeight: 'bold',
              textTransform: 'uppercase',
              whiteSpace: 'nowrap',
            }}
          >
            <cms.Text id="amountDue" />{' '}{price(invoice.balance)}
          </h4>
        </div>
      </div>
      <div>
        <cms.Markdown id="notes" />
      </div>
    </>
  );
}

export default InvoiceDetails;
