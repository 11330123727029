import React from 'react';
import PropTypes from 'prop-types';

import { WurdText } from 'wurd-react';
import DatePicker from './date-picker';
import TimeSelect from './time-select';

function ScheduleTimeForm({
  timeslots,
  prefix = '', // Prefix for name attributes
  date,
  timeslot,
  onChange,
  ...props
}) {
  const dateName = prefix ? prefix + 'Date' : 'date';
  const timeslotName = prefix ? prefix + 'Timeslot' : 'timeslot';

  return (
    <fieldset {...props}>
      <div className="form-group">
        <div className="col-xs-6">
          <label className="required"><WurdText id="timeForm.date.label" /></label>
          <DatePicker
            name={dateName}
            required
            timeslots={timeslots}
            value={date}
            onChange={date => {
              onChange({ [dateName]: date, [timeslotName]: timeslot })
            }}
          />
        </div>
        <div className="col-xs-6">
          <label className="required"><WurdText id="timeForm.time.label" /></label>
          <TimeSelect
            name={timeslotName}
            required
            timeslots={timeslots}
            selectedDate={date}
            value={timeslot}
            onChange={e => {
              onChange({ [dateName]: date, [timeslotName]: e.target.value })
            }}
          />
        </div>
      </div>
    </fieldset>
  );
}

ScheduleTimeForm.propTypes = {
  date: PropTypes.string,
  timeslot: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

export default ScheduleTimeForm;
