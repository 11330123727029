import moment from 'moment';
import _ from 'underscore';
import wurd from 'wurd-react';

import api, { getMarket } from './api';


/**
 * Get timeslot start/end times based on locale
 * @param {String} timeslot       E.g. '1100-1300'
 * @return {String}               E.g. '11am to 1pm'
 */
export function getTimeslot(timeslot = '', timeFormat = 'HH:mm') {
  const [start, end = ''] = timeslot.split('-'),
    startHours = start.substr(0, 2),
    startMins = start.substr(2, 4),
    endHours = end.substr(0, 2),
    endMins = end.substr(2, 4);

  const startStr = moment.utc().startOf('day')
    .add(startHours, 'hours')
    .add(startMins, 'minutes')
    .format(timeFormat);

  const endStr = moment.utc().startOf('day')
    .add(endHours, 'hours')
    .add(endMins, 'minutes')
    .format(timeFormat);

  return { start: startStr, end: endStr };
}

export function formatTimeslot(timeslot, timeFormat) {
  return wurd.text('common.timeslot', getTimeslot(timeslot, timeFormat));
}

export function isDelivery(valetOrder) {
  return valetOrder.deliverItems?.length > 0 || hasOrderedItems(valetOrder);
}

export function isCollection(valetOrder) {
  return valetOrder.collectItems?.length > 0 || hasBulkyItems(valetOrder);
}

export function getNextDelivery(visits) {
  return visits.find(isDelivery);
}

export function getNextCollection(visits) {
  return visits.find(isCollection);
}

export function getType(valetOrder) {
  const isDel = isDelivery(valetOrder);
  const isCol = isCollection(valetOrder);

  if (isDel && isCol && valetOrder.collect === 'immediately') {
    return 'both';
  }
  else if (isDel) {
    return 'delivery';
  }
  else if (isCol) {
    return 'collection';
  }
  else {
    return 'generic';
  }
}

export function hasOrderedItems(valetOrder) {
  return !!_.find(valetOrder.boxCounts, (numOrdered, type) => {
    return numOrdered > 0;
  });
}

export function hasBulkyItems(valetOrder) {
  return !!_.find(valetOrder.bulkyCounts, (numOrdered, type) => {
    return numOrdered > 0;
  });
}

export function hasOrderedProducts(valetOrder) {
  return !!_.find(valetOrder.productCounts, (numOrdered, id) => {
    return numOrdered > 0;
  });
}

export function isEmptyBoxDelivery(valetOrder) {
  return hasOrderedItems(valetOrder);
}

export function fetchAvailableTimeslots() {
  let url = `/v1/settings/timeslots`;

  const marketId = getMarket();
  if (marketId) {
    url += `?market=${marketId}`;
  }

  return api.get(url);
}

/**
 * Checks if the valet order can still be rescheduled by the customer
 */
export function isWithinRescheduleWindow(valetOrder) {
  const maxHours = 24;

  const orderDate = moment(valetOrder.date);
  const now = moment();

  const hoursDifference = orderDate.diff(now, 'hours');

  return hoursDifference > maxHours;
}

export function canReschedule(valetOrder = {}) {
  return valetOrder.state === 'submitted' && isWithinRescheduleWindow(valetOrder);
}

export function getTotalEmpties(valetOrder) {
  if (!valetOrder.boxCounts) return 0;

  return Object.entries(valetOrder.boxCounts).reduce((total, [type, quantity]) => total + quantity, 0);
}
