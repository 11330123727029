// Styles
import './styles/app.scss';

// Rollbar error tracking
// import './rollbar';

// import attachFastClick from 'fastclick'; // eslint-disable-line no-unused-vars

import React from 'react';
import ReactDOM from 'react-dom';
import wurd from 'wurd-react';

import * as language from './utils/language';
import analytics from './utils/analytics';
import cmsCache from './utils/cms-cache';
import Router from './router';
import store from './store';
import { fetchSettings } from './actions';


function render() {
  ReactDOM.render(<Router />, document.getElementById('app'));
}

async function start() {
  const settings = await fetchSettings();

  document.title = settings.brand?.name;

  // check if favicon is there
  let icon = document.head.querySelector('link[rel=icon]');
  if (!icon) {
    icon = document.createElement('link');
    icon.rel = 'icon';
    document.head.appendChild(icon);
  }
  icon.href = settings.brand && settings.brand.icon;

  let customStyles = document.getElementById('settings-styles');
  if (!customStyles) {
    customStyles = document.createElement('style');
    customStyles.id = 'settings-styles';
    document.head.appendChild(customStyles);
  }
  customStyles.textContent = `.sg-navbar, .sg-navbar__dropdown {
background-color: ${settings.brand && settings.brand.color} !important;
}
.sg-navbar__link {
color: ${settings.brand && settings.brand.textColor} !important;
}
.sg-navbar__link--active, .sg-navbar__link.active {
color: ${settings.brand && settings.brand.textColor} !important;
}`;

  analytics.init(settings);
  window.tracking = analytics; // TMP

  // Determine the user's preferred language
  language.init(settings.languages);

  // Re-render when Wurd content is updated
  const sections = [
    'addressForm',
    'billing',
    'common',
    'forgotPassword',
    'items',
    'login',
    'orderBoxes',
    'orderFlow',
    'orderPlans',
    'profile',
    'reschedule',
    'resetPassword',
    'scheduleFlow',
    'signup',
    'storefront',
    'timeForm',
    'orderSummary',
    'invoices',
    'invoiceDetails',
  ];

  const activeLang = language.get();
  const wurdLang = settings.wurdLanguages ? settings.wurdLanguages[activeLang] : activeLang;

  try {
    wurd.connect('storeganise-user', {
      editMode: 'querystring',
      lang: wurdLang,
      rawContent: cmsCache.get(),
    });

    // Show UI with cached content if available
    render();

    await wurd.load(sections.join(','));

    render();
    cmsCache.set(wurd.store.rawContent);
  } catch (err) {
    console.log(err.message, `Fallback to ${activeLang} for wurd`);

    wurd.connect('storeganise-user', {
      editMode: false,
      lang: activeLang,
    });

    // Show UI with cached content if available
    render();

    await wurd.load(sections.join(','));
  } finally {
    render();
    cmsCache.set(wurd.store.rawContent);
  }

  // Re-render when store data changes
  store.onChange(render);
}

start();
