import React from 'react';
import PropTypes from 'prop-types';
import { TransitionGroup, CSSTransition } from 'react-transition-group';

import Item from './item.react';


function ItemList({ selectedItems, items }) {
  if (!items) return null;

  // it used to be transitionName="pop" but I can't find a Pop transition in react-bootstrap yet
  return (
    <TransitionGroup className="item-gallery gallery-cont row" component="div">
      {items.map((item) =>
        <CSSTransition key={item.id} timeout={300} classNames="pop" appear>
          <Item item={item} isSelected={selectedItems.includes(item.id)} />
        </CSSTransition>
      )}
    </TransitionGroup>
  );
}

ItemList.propTypes = {
  items: PropTypes.array.isRequired,
  selectedItems: PropTypes.array.isRequired
};

export default ItemList;
