import React from 'react';
import _ from 'underscore';

import wurd from 'wurd-react';
import { price as formatPrice } from '../../../utils/ui';

import PropTypes from 'prop-types';

export default class ScheduleAreaSelect extends React.Component {
  static propTypes = {
    settings: PropTypes.object.isRequired,
    areas: PropTypes.array.isRequired,
    lang: PropTypes.string.isRequired,
    defaultValue: PropTypes.string
  };

  state = {
    areaNote: this.getAreaNote(this.props.defaultValue)
  }

  getAreaNote(areaId) {
    if (!areaId) return null;

    const { areas, lang } = this.props;
    const area = _.findWhere(areas, { id: areaId });
    if (!area) return null;

    const note = area.note && area.note[lang];
    if (!note) return null;

    return note;
  }

  render() {
    const { settings, areas = [], lang, ...props } = this.props;
    const { areaNote } = this.state;

    return (
      <div>
        <select
          name="area"
          className="form-control"
          required
          {...props}
          onChange={e => {
            if (props.onChange) props.onChange(e);
            this.setState({ areaNote: this.getAreaNote(e.target.value) });
          }}
        >
          <option value="">{wurd.text('addressForm.area.placeholder')}</option>

          {areas.map(area => {
            let label = (area.title && area.title[lang]) || area._id || area.id;

            if (area.surcharge) {
              label += ' ' + wurd.text('addressForm.area.surcharge', {
                surcharge: formatPrice(area.surcharge)
              });
            }

            return <option key={area._id || area.id} value={area._id || area.id}>{label}</option>
          })}
        </select>

        {areaNote && (
          <div className="alert alert-warning">
            {areaNote}
          </div>
        )}
      </div>
    );
  }
}
