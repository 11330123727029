import React from 'react';
import createReactClass from 'create-react-class';
import PropTypes from 'prop-types';
import _ from 'underscore';
import { WurdText, WurdMarkdown } from 'wurd-react';

import * as itemHelpers from '../../../utils/item-helpers';
import { stepsGetError } from '../../../utils/order-helpers';

import ItemCounter from '../item-counter';
import Submit from '../../form/submit';

var BoxesStep = createReactClass({

  propTypes: {
    settings: PropTypes.object.isRequired,
    onComplete: PropTypes.func.isRequired,
    valetOrder: PropTypes.object
  },

  componentDidUpdate(prevProps) {
    if (this.props.valetOrder !== prevProps.valetOrder) {
      this.setState(this.getState());
    };
  },

  getInitialState() {
    return this.getState();
  },

  getState() {
    const { settings = {}, valetOrder: { boxCounts = {}, bulkyCounts = {} } = {} } = this.props;

    var state = {
      errMsg: '',
      submitting: false
    };

    _.each(settings.items, item => {
      state[item.type] = boxCounts[item.type] !== undefined
        ? boxCounts[item.type]
        : bulkyCounts[item.type] !== undefined
          ? bulkyCounts[item.type]
          : item.quantity || 0;
    });

    return state;
  },

  render() {
    const { settings = {} } = this.props;
    var state = this.state;

    return (
      <div className="schedule-step">
        <h2 className="sg-section-title"><WurdText id="orderBoxes.title" /></h2>

        <div className="content">
          <WurdMarkdown id="orderBoxes.intro" />

          {_.map(settings.items, (item, index) => {
            var type = item.type;
            if (!type) return null;

            var getLang = itemHelpers.getLangValue.bind(null, item);

            //If maxQuantity is 0, treat the item as hidden from the ordering process
            if (item.quantity === 0 && item.maxQuantity === 0) return null;

            return (
              <ItemCounter
                key={type}
                type={type}
                price={getLang('displayPrice')}
                count={state[type] || 0}
                onChange={_.partial(this.onItemCounterChange, type)}
                title={getLang('title')}
                desc={getLang('info')}
                image={item.image}
                first={index === 0}
                maxCount={item.maxQuantity}
              />
            );
          })}

          <Submit
            submitting={state.submitting}
            errMsg={state.errMsg}
            text="common.buttons.next"
            onClick={this.onSubmit}
          />
        </div>
      </div>
    );
  },

  /**
   * Returns collected data for entire form
   *
   * @return {Object}
   */
  getValue() {
    var { settings } = this.props;
    var state = this.state;

    var boxCounts = {};
    var bulkyCounts = {};
    _.each(settings.items, itemSetting => {
      if (itemSetting.type.substr(0, 5) === 'bulky') {
        bulkyCounts[itemSetting.type] = state[itemSetting.type];
      } else {
        boxCounts[itemSetting.type] = state[itemSetting.type];
      }
    });

    return {
      boxCounts,
      bulkyCounts
    };
  },

  onSubmit(event) {
    if (event) event.preventDefault();

    const { settings, skipValidation } = this.props;
    const data = this.getValue();

    const errMsg = !skipValidation && stepsGetError.boxes(data);
    if (errMsg) {
      return this.setState({ errMsg });
    }

    this.props.onComplete(data);
  },

  /**
   * Updates the state (count) for the given item counter
   *
   * @param {String} type     'box', 'bulky' etc.
   * @param {Number} count
   */
  onItemCounterChange(type, count) {
    this.setState({
      [type]: count
    });
  }
});

export default BoxesStep;
