/* eslint-disable jsx-a11y/anchor-is-valid */

import React from 'react';
import createReactClass from 'create-react-class';

import store from '../store';
import { addItemImage } from '../actions';


class ImgLoader extends React.Component {
  state = {}
  componentDidMount() {
    const img = new Image();
    img.onload = evt => {
      this.setState({ loaded: true, failed: false });
    };
    img.onerror = evt => {
      this.setState({ loaded: true, failed: true });
    }
    img.src = this.props.url;
  }
  render() {
    const { children } = this.props;
    return typeof children !== 'function' ? children : children(this.state);
  }
}


var ItemImage = createReactClass({
  getInitialState: function () {
    return {
      loading: false
    };
  },

  render: function () {
    const { item, onClick } = this.props;

    var customImage = (item.images && item.images[0]) || (item.opsImages && item.opsImages[0]);

    if (customImage) {
      return (
        <a className="img-container" onClick={onClick}>
          <ImgLoader url={customImage.url} key="loader">
            {({ loaded }) => !loaded && (
              <div className="img-spinner text-center text-muted">
                <i className="fa fa-spinner fa-spin"></i>
              </div>
            )}
          </ImgLoader>

          <div className="img" style={{ backgroundImage: `url(${customImage.url})` }}></div>
        </a>
      );
    } else {
      return (
        <div className="img-container">
          <ImgLoader url={item.typeImage} key="loader">
            {({ loaded }) => (this.state.loading || !loaded) && (
              <div className="img-spinner text-center text-muted">
                <i className="fa fa-spinner fa-spin"></i>
              </div>
            )}
          </ImgLoader>

          {(!this.state.loading) ? <div className="img" style={{ backgroundImage: `url(${item.typeImage})` }}></div> : null}

          {
            (!this.state.loading)
              ? (
                <div className="add-photo fa fa-camera">
                  <input type="file" accept="image/*" onChange={this.onSelectImage} />
                </div>
              )
              : null
          }
        </div>
      );
    }
  },

  onSelectImage: function (event) {
    //Exit if user cancelled file selection
    const input = event.target;
    if (!input.files.length) return;

    const { item } = this.props;
    const file = input.files[0];

    this.setState({ loading: true });

    addItemImage(item.id, file)
      .catch(err => {
        window.alert(`Could not upload the image ${err.message}`);
        console.error(err);
      })
      .finally(() => {
        this.setState({ loading: false });
      })
  }
});

export default ItemImage;
