import React from 'react';
import { withRouter } from 'react-router-dom';
import createReactClass from 'create-react-class';
import PropTypes from 'prop-types';

import store from '../../store';
import * as language from '../../utils/language';
import { signup } from '../../actions';
import wurd, { WurdMarkdown } from 'wurd-react';
import { getMarket } from '../../utils/api';

import { Form, Field, Input } from '../form';


var SignupForm = createReactClass({
  getInitialState: function () {
    return {
      isSubmitting: false,
      error: null
    };
  },

  onSubmit: async function (data) {
    //Add extra info for signup
    data.language = language.get();
    data.marketId = getMarket();

    this.setState({ isSubmitting: true });

    //Signup
    try {
      await signup(data);
      // this.setState({
      //   error: null,
      //   isSubmitting: false
      // });

      this.props.onComplete();
    } catch (err) {

      this.setState({
        isSubmitting: false,
        error: err.errors.email && wurd.text('signup.errors.existingEmail', { email: data.email }) || err
      });
    }
  },

  render: function () {
    const state = this.state;
    const { settings } = store.get();

    return (
      <Form
        submitText="signup.submit"
        onSubmit={this.onSubmit}
        isSubmitting={state.isSubmitting}
        error={state.error}
      >
        <Field icon="user">
          <Input
            placeholder="signup.firstName"
            name="firstName"
            required
            autoFocus
          />
        </Field>

        <Field icon="user">
          <Input
            placeholder="signup.lastName"
            name="lastName"
            required
          />
        </Field>

        <Field icon="envelope">
          <Input
            placeholder="signup.email"
            type="email"
            name="email"
            validate={e =>
              /^\S+@\S+\.\S+$/.test(e.target.value) ? '' : wurd.text('signup.errors.invalidEmail')
            }
            required
          />
        </Field>

        <Field icon="lock">
          <Input
            placeholder="signup.password"
            type="password"
            name="password"
            validate={e =>
              e.target.value.length < 7 ? wurd.text('signup.errors.passwordTooShort', { min: 7 }) : ''
            }
            required
          />
        </Field>

        <div className="spacer text-muted clearfix" style={{ marginBottom: '15' }}>
          {settings.requireAgreeTerms
            ? (
              <label>
                <input
                  type="checkbox"
                  className="pull-left"
                  name="agreeTerms"
                  required
                />
                <WurdMarkdown id="signup.agreeTerms" vars={{ termsUrl: settings.termsUrl }} style={{ fontWeight: 'normal', marginLeft: 15 }} />
              </label>
            ) : (
              <WurdMarkdown id="signup.agreeTerms" vars={{ termsUrl: settings.termsUrl }} style={{ fontWeight: 'normal' }} />
            )}
        </div>
      </Form>
    );
  }
});

SignupForm.propTypes = {
  onComplete: PropTypes.func.isRequired
};

export default withRouter(SignupForm);
