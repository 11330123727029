import React from 'react';
import { withRouter } from 'react-router-dom';
import { Collapse } from 'react-bootstrap';
import classNames from 'classnames';
import _ from 'underscore';

import { WurdText, WurdMarkdown } from 'wurd-react';

import PropTypes from 'prop-types';
import * as visitHelpers from '../../../utils/visit-helpers';
import { stepsGetError } from '../../../utils/order-helpers';

import TimeForm from './form';
import PromoCodeForm from './promocode-form';
import Submit from '../../form/submit';


class TimeStep extends React.Component {
  static propTypes = {
    settings: PropTypes.object.isRequired,
    onComplete: PropTypes.func.isRequired,
    textKey: PropTypes.string.isRequired,
    valetOrder: PropTypes.object,
    isSubmitting: PropTypes.bool,
    errMsg: PropTypes.string,
    includePromoCode: PropTypes.bool
  };

  static defaultProps = {
    valetOrder: {}
  }

  state = {
    ..._.pick(this.props.valetOrder, [
      'date',
      'timeslot',
      'collect',
      'collectDate',
      'collectTimeslot',
      'promoCode'
    ]),
    availableTimeslots: [],
    errMsg: this.props.errMsg || '',
  }

  componentDidMount() {
    ;
    visitHelpers.fetchAvailableTimeslots()
      .then(timeslots => {
        this.setState({ availableTimeslots: timeslots });
      })
  }

  onChange = (obj) => {
    const newState = { ...this.state, ...obj };

    if (visitHelpers.hasOrderedItems(this.props.valetOrder) && newState.collectDate <= newState.date) {
      newState.collectDate = null;
    }

    this.setState(newState);
  }

  onSubmit = (event) => {
    if (event) event.preventDefault();

    const errMsg = stepsGetError.time(this.state);
    if (errMsg) {
      return this.setState({ errMsg });
    }

    const value = _.pick(this.state, [
      'date',
      'timeslot',
      'promoCode'
    ]);

    if (visitHelpers.hasOrderedItems(this.props.valetOrder)) {
      value.collect = this.state.collect;

      if (this.state.collect !== 'immediately') {
        _.extend(value, _.pick(this.state, [
          'collectDate',
          'collectTimeslot'
        ]));
      }
    }

    this.props.onComplete(value);
  }

  /**
   * Gets timeslots the user can select for delivery
   *
   * @return {Object[]}
   */
  getDeliveryTimeslots() {
    /*var timeslots = this.state.availableTimeslots || [];

    var endDate = moment().add(4, 'weeks').add(1, 'day').format('YYYY-MM-DD');

    var lastDateIndex = _.findIndex(timeslots, function(timeslot) {
      return timeslot.date > endDate;
    });

    //Get 2 weeks worth of dates
    return timeslots.slice(0, lastDateIndex);*/


    //Get all timeslots except for 1 week at the end, so there are always collection timeslots available
    const { availableTimeslots = [] } = this.state;

    return availableTimeslots.slice(0, -7);
  }

  /**
   * Gets timeslots the user can select for collection (i.e. dates after delivery)
   *
   * @return {Object[]}
   */
  getCollectionTimeslots() {
    const { availableTimeslots = [] } = this.state;

    const deliverDate = this.state.date;
    if (!deliverDate) return [];

    /*//Get 2 weeks of dates after the selected delivery date
    var endDate = moment(deliverDate).add(4, 'weeks').format('YYYY-MM-DD');

    var startDateIndex = _.findIndex(availableTimeslots, function(timeslot) {
      return timeslot.date > deliverDate;
    });

    var endDateIndex = _.findIndex(availableTimeslots, function(timeslot) {
      return timeslot.date > endDate;
    });

    return availableTimeslots.slice(startDateIndex, endDateIndex);*/


    var startDateIndex = _.findIndex(availableTimeslots, timeslot => timeslot.date > deliverDate);

    return availableTimeslots.slice(startDateIndex);
  }

  render() {
    const { isSubmitting, includePromoCode } = this.props;
    const { date, timeslot, promoCode, errMsg } = this.state;

    return (
      <div className="schedule-step">
        <h2 className="sg-section-title"><WurdText id="scheduleFlow.time.title" /></h2>

        <div className="content clearfix">
          <WurdMarkdown id="scheduleFlow.time.text" />

          <TimeForm
            timeslots={this.getDeliveryTimeslots()}
            onChange={this.onChange}
            date={date}
            timeslot={timeslot}
          />
        </div>

        {this.renderCollectSection()}

        {includePromoCode &&
          <div className="content clearfix">
            <hr />
            <PromoCodeForm
              onChange={this.onChange}
              data={{ promoCode }}
            />
          </div>
        }

        <div className="content clearfix">
          <div className="footer">
            <Submit
              submitting={isSubmitting}
              errMsg={errMsg || this.props.errMsg}
              text="common.buttons.submit"
              onClick={this.onSubmit}
            />
          </div>
        </div>
      </div>
    );
  }

  renderCollectSection() {
    const { settings, valetOrder } = this.props;
    const { collect, collectDate, collectTimeslot } = this.state;

    //If 'collect' was not set (to either 'later' or 'immediately' don't show this part of the form
    //If a single delivery or collection is being scheduled this won't be shown
    if (!collect) return null;

    //If only bulky items were ordered, don't show this extra time section
    if (!visitHelpers.hasOrderedItems(valetOrder)) return null;

    const wrapperProps = settings.collectionWaitTime > 0 && {
      className: 'collect-option clearfix',
      onClick: () => this.setState({ collect: 'later' }),
    };

    return (
      <div style={{ marginTop: 60 }}>
        <h2 className="sg-section-title"><WurdText id="orderFlow.collectTime.title" /></h2>

        <div className="content clearfix">
          <WurdMarkdown id="orderFlow.collectTime.text" />

          <div {...wrapperProps}>
            {settings.collectionWaitTime > 0 && (
              <i className={classNames({
                'radio fa': true,
                'fa-check-circle': collect === 'later',
                'fa-circle-o': collect === 'immediately'
              })} />
            )}

            <div>
              <h4><WurdText id="orderFlow.collectTime.later.title" /></h4>
              <div className="text-muted"><WurdText id="orderFlow.collectTime.later.text" /></div>
            </div>
            <Collapse in={collect === 'later'} timeout={300}>
              <TimeForm
                timeslots={this.getCollectionTimeslots()}
                prefix="collect"
                date={collectDate}
                timeslot={collectTimeslot}
                onChange={this.onChange}
              />
            </Collapse>
          </div>

          {
            (settings.collectionWaitTime > 0) &&
            <div className="collect-option clearfix" onClick={() => this.setState({ collect: 'immediately' })}>
              <i className={classNames({
                'radio fa': true,
                'fa-check-circle': collect === 'immediately',
                'fa-circle-o': collect === 'later'
              })}></i>
              <div>
                <h4><WurdText id="orderFlow.collectTime.immediately.title" /></h4>
                <div className="text-muted">
                  <WurdMarkdown
                    id="orderFlow.collectTime.immediately.text"
                    vars={{ mins: settings.collectionWaitTime }}
                  />
                </div>
              </div>
            </div>
          }
        </div>
      </div>
    )
  }
}

export default withRouter(TimeStep);
