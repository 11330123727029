import React from 'react';

export default ({children}) => (
  <div className="schedule-page middle-block">
    <div className="middle-block__content">
      <div className="block-flat">
        {children}
      </div>
    </div>
  </div>
);
