import wurd from 'wurd-react';
import * as authHelpers from './auth-helpers';
import * as itemHelpers from '../utils/item-helpers';
import * as visitHelpers from './visit-helpers';
import store from '../store';

/**
 * Gets the promo code that was used when referring the user to the app (if any)
 *
 * @return {String}
 */
/*export const getPromoCode = function() {
  console.log('getPromoCode')
  var referrer = StateStore.get().referrer || {};
  
  return referrer.promoCode;
};*/

// if a prop in this object, called with the app state is returning true, we can skip this step
const stepsCanSkip = {
  plan: (data) => {
    const { user, settings } = store.get();
    return !(settings.plans && settings.plans.length)
      || (user && user.planId)
      || (data && data.planId);
  },
  boxes: (data, mode) => {
    const { settings: { items = [] } } = store.get();

    if (mode === 'schedule') {
      if (!data?.id) return true; // skip only in schedule mode, not reschedule
      return items.length === 0;
    }

    const { boxCounts = {}, bulkyCounts = {} } = data;

    // if at least one item is selected, this step is skippable
    return items.some(item => boxCounts[item.type] > 0 || bulkyCounts[item.type] > 0);
  },
  store: (data, mode) => {
    if (mode === 'schedule') {
      if (!data?.id) return true; // skip only in schedule mode, not reschedule
    }

    const { settings: { products = [] } } = store.get();

    const activeProducts = products.filter(productConfig => productConfig.maxQuantity !== 0);
    return !activeProducts.length;
  },
  account: (data) => { // can skip if user is logged in
    const { user } = store.get();
    return user;
  },
  billing: (data) => {
    const { user, settings } = store.get();
    return authHelpers.hasBillingMethod(settings.billing, user);
  },
  'deliver-items': (data) => {
    if (!data?.id) return true; // skip step for new order or schedule flow

    return itemHelpers.getItemsInStorage().length === 0;
  },
  'collect-items': (data) => {
    if (!data?.id) return true; // skip step for new order or schedule flow

    return itemHelpers.getItemsWithUser().length === 0;
  },
  summary: (data, mode) => {
    if (mode === 'schedule' && !data?.id) return true; // skip only in schedule mode, not reschedule
  },
};

/**
 * Check if this step has an error
 * @returns {string} errorMsg: if it's falsy the step is valid
 * To decide is a step is valid stepsCanSkip should be used first on the same key
 */
export const stepsGetError = {
  plan: (data) => {
    if (!data.planId) {
      return wurd.text('orderPlans.mustSelect') || 'Select a plan';
    }
  },
  boxes: (data, mode) => {
    if (mode === 'schedule') return '';
    const { boxCounts = {}, bulkyCounts = {} } = data;
    const { settings: { items = [] } } = store.get();

    // Check at least a box or bulky item was selected
    if (!items.some(item => boxCounts[item.type] > 0 || bulkyCounts[item.type] > 0)) {
      return wurd.text('orderBoxes.mustSelect') || 'Select at least 1 item';
    }
  },
  store: () => {
    return '';
  },
  address: (data) => {
    const { area, address, phone } = data;
    if (!area || !address || !phone) {
      return wurd.text('addressForm.missingFields') || 'Missing required fields';
    }
  },
  time: (data) => {
    const { date, timeslot, collect, collectDate, collectTimeslot } = data;
    if (!date || !timeslot) {
      return wurd.text('timeForm.missingFields') || 'Missing required fields';
    }

    // Check that collect date is after deliver date
    if (visitHelpers.hasOrderedItems(data) && collect === 'later') {
      if (date >= collectDate || !collectDate || !collectTimeslot) {
        return wurd.text('timeForm.missingCollect') || 'The collection date must be set after the delivery date';
      }
    }
  },
  account: () => {
    return '';
  },
  billing: () => {
    return '';
  },
  summary: () => {
    return '';
  },
};


/**
 * returns next step in order flow given the current state
 * @param {object} data valetOrder data 
 * @param {array} [opts.steps] All the possible steps, the have a corresponding component in components/schedule
 * @param {string} [opts.step] optional currentStep from url for example, stop there if reaching that step
 * @param {boolean} [opts.noSkip] optional, if true stop exactly at opts.step at most, (don't skip further)
 * @param {'schedule'|'order'} [opts.mode]
 * @returns {string} step
 */
export default function getStep(data, { steps = Object.keys(stepsGetError), step, noSkip, mode } = {}) {
  const curI = steps.indexOf(step);

  let i = 0;
  // from 0 to curI, all steps must validate, else we stop at the first not validating
  for (; i <= curI; i++) { // if currentStep is undefined, start is -1, so we start searching from 0, all good
    const canSkip = stepsCanSkip[steps[i]];

    if (canSkip && canSkip(data, mode)) { // can skip
      continue;
    }

    const getError = stepsGetError[steps[i]];
    if (!getError?.(data, mode)) { // no error, so continue
      continue;
    }

    // this step is not valid, so return it
    return steps[i];
  }

  if (noSkip) return step || steps[i];

  // then check next steps, return the first one you can't skip
  for (; i < steps.length; i++) {
    const canSkip = stepsCanSkip[steps[i]];

    if (canSkip && canSkip(data, mode)) { // can skip
      continue;
    }
    // can't skip, so return
    return steps[i];
  }

  return undefined; // we're done, all steps are passed
}
