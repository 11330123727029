import React from 'react';
import createReactClass from 'create-react-class';
import PropTypes from 'prop-types';
import {WurdText} from 'wurd-react';


var SchedulePromoCodeForm = createReactClass({

  propTypes: {
    onChange: PropTypes.func.isRequired,
    data: PropTypes.object.isRequired
  },

  render() {
    const {data} = this.props;

    return (
      <div className="col-sm-6">
        <div className="form-group">
          <label><WurdText id="signup.promoCodeBtn" /></label>
          <input 
            type="text" 
            className="form-control"
            name="promoCode"
            value={data.promoCode || ''}
            onChange={this.onChange}
          />
        </div>
      </div>
    );
  },

  onChange(event) {
    var {name, value} = event.target;
    
    this.props.onChange({
      [name]: value
    });
  }
});

export default SchedulePromoCodeForm;
