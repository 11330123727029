import React from 'react';
import {Link} from 'react-router-dom';

import {WurdText} from 'wurd-react';
import SignupForm from './signup-form';


export default function SignupPage() {
  return (
    <div>
      <h2><WurdText id="signup.title" /></h2>

      <SignupForm onComplete={(() => {
        // nothing to do, SignupForm calls init
      })} />

      <div className="footer">
        <Link to="/login"><WurdText id="signup.login" /></Link>
      </div>
    </div>
  );
}
