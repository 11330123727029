import React from 'react';
import PropTypes from 'prop-types';

import Submit from './submit';

/*
TODO make this stateful
with isSubmitting, errMsg in state
wrap onSubmit in a try { await onSubmit(data); this.setState({isSub:false}) } catch(err){this.setState({isSub:false, errMsg:err.message})}
then no need for consumers of Form to pass a isSublitting and errMsg
*/

function Form({
  children,
  isSubmitting,
  errMsg,
  error,
  submitBtn=true,
  submitText='common.buttons.submit',
  onSubmit,
  ...props
}) {
  return (
    <form
      {...props}
      onSubmit={e => {
        e.preventDefault();
        const elements = [...e.currentTarget.elements].filter(el => el.name); // take all form elements with a name
        onSubmit(
          elements.reduce((o, el) => ({
            ...o,
            [el.name]: el.type==='checkbox' ? el.checked : el.value
          }), {})
        );
      }}
    >
      {children}
  
      {submitBtn && <Submit 
        submitting={isSubmitting}
        errMsg={errMsg}
        error={error}
        text={submitText}
      />}
    </form>
  );
}

Form.propTypes = {
  submitBtn: PropTypes.bool,
  submitText: PropTypes.string,
  onSubmit: PropTypes.func,
  errMsg: PropTypes.string,
  isSubmitting: PropTypes.bool
};

export default Form;
