import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Fade } from 'react-bootstrap';
import wurd, { WurdText } from 'wurd-react';

import store from '../store';
import * as itemHelpers from '../utils/item-helpers';

import ItemImage from './item-image.react';
import ItemEditor from './item-editor.react';


export default class Item extends React.Component {
  static propTypes = {
    item: PropTypes.object.isRequired,
    isSelected: PropTypes.bool
  };

  state = {
    showEditor: this.props.item._isNew ? true : false,
    editorClassName: ''
  };

  render() {
    const state = this.state;
    const { item, className } = this.props;
    const { items, selectedItems } = store.get();

    const order = itemHelpers.getOrder(item);

    return (
      <div className={`item col-xs-12 col-sm-4 col-md-4 col-lg-4 ${item.type} ${className}`}>
        <Fade in={state.showEditor} timeout={1900}>
          <ItemEditor
            key={item.id}
            className={state.editorClassName}
            style={!state.showEditor ? { zIndex: -1 } : undefined}
            item={item}
            onRequestClose={xs => {
              if (item._isNew) {
                store.set({ items: items.filter(o => o.id !== item.id) });
              } else {
                this.setState({ showEditor: false });
              }
            }}
          />
        </Fade>

        <div className="sg-item photo">
          <ItemImage item={item} onClick={() => this.setState({ showEditor: true })} />

          <div className="head" onClick={() => this.setState({ showEditor: true })}>
            <i className="fa fa-pencil edit-icon"></i>

            <h4 className="sg-item__title single-line">
              {itemHelpers.getDisplayTitle(item) || <>&nbsp;</>}
            </h4>
            <div className="sg-item__id desc">{`#${(item.sid || '').toUpperCase()}`}</div>
          </div>

          <div className="sg-item__state">
            <button
              type="button"
              className={this.getSelectButtonClass(item, order)}
              onClick={() => {
                store.set({
                  selectedItems: selectedItems.includes(item.id) ?
                    selectedItems.filter(id => id !== item.id) :
                    [...selectedItems, item.id]
                })
              }}
            >
              <i className={this.getSelectButtonIcon(item, order)} />
              &nbsp;
              {this.getSelectButtonText(item, selectedItems, order)}
            </button>
          </div>
        </div>
      </div>
    );
  }

  getSelectButtonClass(item, order) {
    var str = 'sg-item__btn select-btn btn btn-block btn-flat';

    if (order) {
      str += ' btn-default disabled';
    } else if (this.props.isSelected) {
      str += ' btn-success';
    } else {
      str += ' btn-default';
    }

    return str;
  }

  getSelectButtonIcon(item, order) {
    if (order) {
      return '';
    } else if (item._selected) {
      return 'fa fa-check';
    } else {
      return 'fa fa-truck';
    }
  }

  getSelectButtonText(item, selectedItems, order) {
    //With user
    if (item.state === 'user') {
      if (order) {
        const date = order.collectDate || order.date;

        return (
          <WurdText
            id="items.button.collect_scheduled"
            vars={{
              date: moment(date).format(wurd.text('items.button.dateFormat'))
            }}
          />
        );
      } else if (selectedItems.includes(item.id)) {
        return <WurdText id="items.button.collect_selected" />
      } else {
        return <WurdText id="items.button.collect" />
      }
    }

    //In storage
    else {
      if (order) {
        const date = order.date;

        return (
          <WurdText
            id="items.button.deliver_scheduled"
            vars={{
              date: moment(date).format(wurd.text('items.button.dateFormat'))
            }}
          />
        );
      } else if (selectedItems.includes(item.id)) {
        return <WurdText id="items.button.deliver_selected" />
      } else {
        return <WurdText id="items.button.deliver" />
      }
    }
  }
}
