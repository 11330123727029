/**
 * A super simple store for global data
 * such as user info etc.
 */
let storeData = {
  settings: {},
  user: undefined,
  language: undefined,
  error: undefined,
  valetOrders: [],
  items: [],
  visits: [],
  selectedItems: [],
};
const onChangeListeners = [];

/**
 * Get store data
 *
 * Usage:
 * const key = store.get('key')
 * const { key } = store.get()
 *
 * @param {String} [key]
 *
 * @return {Mixed}
 */
function get(key) {
  if (key) return storeData[key];

  return storeData;
}


/**
 * Set store data and trigger onChange listeners.
 * Merges given object data into the existing store data.
 *
 * Usage:
 * store.set({ foo: 'bar' });
 *
 * @param {String} key
 *
 * @return {Mixed}
 */
function set(newData) {
  const oldData = storeData;
  storeData = { ...storeData, ...newData };
  onChangeListeners.forEach((fn) => fn.call(undefined, storeData, oldData));
}


function onChange(fn) {
  onChangeListeners.push(fn);
}


export default {
  get,
  set,
  onChange,
};
