import React from 'react';
import {Link} from 'react-router-dom';
import createReactClass from 'create-react-class';

import PropTypes from 'prop-types';

import wurd, {WurdText} from 'wurd-react';
import * as visitHelpers from '../utils/visit-helpers';

import GetStarted from './items-get-started.react';


var ItemsUserEmpty = createReactClass({
  render: function() {
    return (
      <div>
        <div className="no-results">
          <div className="row">
            <div className="col-sm-offset-2 col-sm-8 col-md-offset-3 col-md-6">
                
              { this.renderContent() }

            </div>
          </div>
        </div>
      </div>
    );
  },

  renderContent: function() {
    var props = this.props;

    var hasItemsInStorage = !!props.itemsInStorage.length;
    var nextDelivery = props.nextDelivery;
    var hasEmptyBoxDelivery = nextDelivery && visitHelpers.isEmptyBoxDelivery(nextDelivery);

    //New user
    if (!hasItemsInStorage && !nextDelivery) {
      return <GetStarted />
    }

    //New user who has ordered boxes
    if (!hasItemsInStorage && hasEmptyBoxDelivery) {
      return wurd.map('items.withUser.noResults.hasOrdered', (item, i) => <p key={i}>{item.text()}</p>);
    }

    //Has items in storage - all done
    if (hasItemsInStorage) {
      return (
        <div>
          <div className="success-mark fa fa-check-circle"></div>

          {wurd.map('items.withUser.noResults.hasItemsInStorage', (item, i) => <p key={i}>{item.text()}</p>)}

          <Link className="sg-btn action-btn btn btn-success" to="/order-boxes">
            <i className="fa fa-archive"></i> <WurdText id="items.withUser.orderMoreBtn" />
          </Link>
        </div>
      );
    }

    //Generic no results message
    return wurd.map('items.noResults', (item, i) => <p key={i}>{item.text()}</p>);
  }
});

ItemsUserEmpty.propTypes = {
  itemsInStorage: PropTypes.array,
  nextDelivery: PropTypes.object
};

export default ItemsUserEmpty;
