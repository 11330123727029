import React from 'react';
import createReactClass from 'create-react-class';

import { updateProfile } from '../../actions';
import * as language from '../../utils/language';
import store from '../../store';

import { WurdText, WurdMarkdown } from 'wurd-react';
import Form from '../form/form';
import Field from '../form/label-field';
import Input from '../form/input';
import AreaSelect from '../schedule/address/area-select';


const ProfilePage = createReactClass({
  getInitialState: function () {
    return {
      isSubmitting: false,
      success: false,
      error: null
    };
  },

  onSubmit: async function (data, userId) {
    this.setState({ isSubmitting: true });

    try {
      const user = await updateProfile(userId, data);

      this.setState({
        success: true,
        error: null,
        isSubmitting: false
      });

      setTimeout(() => { // show form back
        this.setState({ success: false });
      }, 5000);

      return user;
    } catch (err) {
      this.setState({
        isSubmitting: false,
        error: err
      });
    }
  },

  render() {
    const { success } = this.state;

    return (
      <div className="schedule-page middle-block">
        <div className="middle-block__content">
          <section className="block-flat">
            <div className="header">
              <h3 className="sg-section-title"><WurdText id="profile.title" /></h3>
            </div>
            <div className="content">
              {success ? this.renderSuccessMsg() : this.renderForm()}
            </div>
          </section>
        </div>
      </div>
    );
  },

  renderForm() {
    const state = this.state;
    const { settings, user } = store.get();

    return (
      <div className="form-horizontal">
        <Form
          submitText="profile.submit"
          onSubmit={data => this.onSubmit(data, user.id)}
          isSubmitting={state.isSubmitting}
          error={state.error}
        >
          <Field
            label="profile.firstName.label"
            help="profile.firstName.help"
          >
            <Input
              name="firstName"
              type="text"
              defaultValue={user.firstName}
              required
            />
          </Field>

          <Field
            label="profile.lastName.label"
            help="profile.lastName.help"
          >
            <Input
              name="lastName"
              type="text"
              defaultValue={user.lastName}
              required
            />
          </Field>

          <Field
            label="profile.address.label"
            help="profile.address.help"
          >
            <Input
              name="address"
              component="textarea"
              defaultValue={user.address}
              required
            />
          </Field>

          <Field
            label="profile.area.label"
            help="profile.area.help"
          >
            {language.get() && <AreaSelect
              name="area"
              settings={settings}
              areas={settings.areas || []}
              lang={language.get()}
              defaultValue={user.area}
              required
            />}
          </Field>
        </Form>
      </div>
    );
  },

  renderSuccessMsg: function () {
    return (
      <div className="alert alert-success">
        <WurdMarkdown id="profile.updateSuccess" />
      </div>
    );
  }
});


export default ProfilePage;
