/**
 * Renders an alert telling the user about their next delivery or collection
 */
import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Link } from 'react-router-dom';
import store from '../store';
import * as ui from '../utils/ui';
import * as visitHelpers from '../utils/visit-helpers';
import * as itemHelpers from '../utils/item-helpers';
import { cancelValetOrder } from '../actions/valet-orders';

import wurd, { WurdText, WurdObject, WurdMarkdown } from 'wurd-react';

import './items-next-visit.react.css';


export default function ItemsNextVisit({ visit }) {
  const { settings } = store.get();
  const dateFormat = wurd.text('items.nextVisit.dateFormat');

  function cancel(visit) {
    if (!visitHelpers.canReschedule(visit)) {
      return ui.alert(`${wurd.text('reschedule.tooLate.title')}\n\n${wurd.text('reschedule.tooLate.text')}`);
    }

    ui.confirm(wurd.text('items.nextVisit.confirmCancel', {
      sid: `#${visit.sid}`,
      date: moment(visit.date).format(dateFormat),
      timeslot: visit.timeslot.replace(/(\d\d)(\d\d)/g, '$1:$2'),
    }), confirmed => {
      if (!confirmed) return;

      return cancelValetOrder(visit.id)
        .catch(err => window.alert(err.message));
    });
  }

  const numEmpties = visitHelpers.getTotalEmpties(visit);

  const collectLater = visitHelpers.isDelivery(visit) && visitHelpers.isCollection(visit) && visit.collect === 'later';

  const desc = [
    numEmpties > 0 && wurd.text('items.nextVisit.empties', { numItems: numEmpties }),
    visit.collectItems?.length > 0 && wurd.text('items.nextVisit.collectItems', { numItems: visit.collectItems.length }),
    visit.deliverItems?.length > 0 && wurd.text('items.nextVisit.deliverItems', { numItems: visit.deliverItems.length }),
  ]
    .filter(Boolean)
    .join(wurd.text('items.nextVisit.separator') || ' · ');

  return (
    <div className="sg-alert alert alert-info clearfix" style={{ padding: '10px 15px', marginBottom: 10 }}>
      <div className="pull-right">
        <div>
          <Link
            className="btn btn-link"
            style={{ marginBottom: '0 !important', padding: 0 }}
            to={`/reschedule/${visit.sid.toUpperCase()}`}
          >
            <i className="fa fa-pencil" /> <WurdText id="items.nextVisit.update" />
          </Link>
        </div>

        <div>
          <button
            type="button"
            className="btn btn-link"
            style={{ marginBottom: '0 !important', padding: 0 }}
            onClick={() => cancel(visit)}
          >
            <i className="fa fa-remove" /> <WurdText id="items.nextVisit.cancel" />
          </button>
        </div>
      </div>

      <WurdMarkdown
        id="items.nextVisit.order2"
        className="next-visit-desc"
        vars={{
          order: visit.sid.toUpperCase(),
          type: wurd.text(`items.nextVisit.type.${visitHelpers.getType(visit)}`),
          date: moment(visit.nextDate).format(dateFormat),
          timeslot: wurd.text('common.timeslot', visitHelpers.getTimeslot(visit.timeslot, settings.dateFormats?.time)),
          collection: collectLater ? wurd.text('items.nextVisit.orderCollection', {
            collectType: wurd.text('items.nextVisit.type.collection'),
            collectDate: moment(visit.collectDate).format(dateFormat),
            collectTimeslot: wurd.text('common.timeslot', visitHelpers.getTimeslot(visit.collectTimeslot, settings.dateFormats?.time)),
          }) : '',
          desc,
        }}
      />

      {wurd.editMode && (
        <WurdObject id="items.nextVisit" keys="dateFormat,confirmCancel,empties,deliverItems,collectItems,separator,type.collection,type.delivery,type.both,type.generic,collectSeparator" type="div">
          <em><i className="fa fa-pencil" /> Edit date format and confirmCancel</em>
        </WurdObject>
      )}
    </div>
  );
}

ItemsNextVisit.propTypes = {
  nextVisit: PropTypes.object
};
