import api from '../utils/api';

export async function setLanguage(code: string, userId?: string) {
  //Save to user if logged in
  if (userId) {
    await api.put('/v1/user', { language: code });
  }
  //Save for next time
  localStorage.setItem('lang', code);

  window.location.reload();
}
