import React from 'react';
import createReactClass from 'create-react-class';
import { withRouter } from 'react-router-dom';
import _ from 'underscore';

import { orderBoxes } from '../../actions';
import getStepHelper, { stepsGetError } from '../../utils/order-helpers';
import store from '../../store';

import PlanStep from './plan';
import BoxesStep from './boxes';
import StoreStep from './store';
import AddressStep from './address';
import TimeStep from './time';
import AccountStep from './account';
import BillingStep from './billing';
import SummaryStep from './summary';

const STEPS = [
  'plan',
  'boxes',
  'store',
  'address',
  'time',
  'account',
  'billing',
  'summary',
];

/**
 * convert a step name e.g. 'plan', 'account' to a pathname e.g; /get-started/plan
 * @param {string} curStep
 * @param {*} props
 * @param {*} data
 */
function getStepPath(curStep, { location }, { boxCounts = {}, bulkyCounts = {}, planId }) {
  const parts = location.pathname.slice(1).split('/');
  const { settings } = store.get();

  // update order customUrl http://help.storeganise.com/en/articles/705017-plans-settings#custom-urls
  const sp = new URLSearchParams(location.search);

  const plan = (settings.plans || []).find(plan => plan.id === planId);
  if (plan) sp.set('plan', plan.name);
  else sp.delete('plan');

  _.each(settings.items, item => {
    const count = boxCounts[item.type] !== undefined
      ? boxCounts[item.type]
      : bulkyCounts[item.type];
    if (count) sp.set(item.type, count);
    else sp.delete(item.type);
  });
  const search = `${sp}`;
  return `/${parts[0]}/${curStep}${search ? '?' + search : ''}`;
}

var OrderPage = createReactClass({
  getInitialState() {
    return {
      errMsg: '',
      isSubmitting: false,
      data: {}
    };
  },

  componentDidMount() {
    this.init();
  },

  componentDidUpdate({ location: { pathname: oldPathname } }) {
    const { location: { pathname } } = this.props;
    if (pathname === '/order-boxes' && oldPathname !== pathname) {
      this.init();
    }
  },

  init() {
    const { location } = this.props;
    const { settings: { plans = [], items = [] } } = store.get();

    const query = new URLSearchParams(location.search);

    const data = {
      collect: 'later',
      promoCode: query.get('code')
    };

    //Allow pre-selecting plan
    if (query.get('plan')) {
      const plan = plans.find(plan => plan.name === query.get('plan'));

      if (plan) {
        // console.log(`Pre-selected the ${plan.title.en} plan`);

        data.planId = plan.id;
      }
      else {
        console.warn(`Invalid plan: ${query.get('plan')}`); // go to next step after plan
      }
    }

    //Allow pre-selecting items
    const { boxCounts, bulkyCounts } = [...query].reduce((memo, [itemType, count]) => {
      const item = items.find(item => item.type === itemType);

      if (item) {
        if (itemType.slice(0, 5) === 'bulky') {
          memo.bulkyCounts[itemType] = +count;
        } else {
          memo.boxCounts[itemType] = +count;
        }
      }

      return memo;
    }, { boxCounts: {}, bulkyCounts: {} });

    if (Object.keys(boxCounts).length || Object.keys(bulkyCounts).length) {
      // console.log(`Pre-selected items`, { boxCounts, bulkyCounts });
      data.boxCounts = boxCounts;
      data.bulkyCounts = bulkyCounts;
    }

    const step = getStepHelper(data, { steps: STEPS });

    this.props.history.replace(getStepPath(step, this.props, data));

    this.setState({ data });
  },

  onStepComplete(stepData) {
    const { data } = this.state;
    const { match: { params: { step } } } = this.props;

    const newData = { ...data, ...stepData };

    const newStep = getStepHelper(newData, { step, steps: STEPS });

    if (!newStep) { // we're done, no more step
      this.submit(newData);
    } else if (newStep === step) {
      this.setState({
        errMsg: stepsGetError[step] && stepsGetError[step](newData),
        data: newData
      });
    } else {
      this.setState({ data: newData }, () => {
        this.props.history.push(getStepPath(newStep, this.props, newData));
        window.scrollTo(0, 0);
      });
    }
  },

  async submit(data) {
    this.setState({ isSubmitting: true });

    try {
      await orderBoxes(data);

      this.props.history.push('/items-user?redirect=0');
    } catch (err) {
      // Go back to the time booking step to show up errors with creating an order
      this.setState({
        isSubmitting: false,
        errMsg: err.message
      });
      this.props.history.push(getStepPath('time', this.props, data));
    }
  },

  render() {
    const { match: { params: { step: stepFromUrl } } } = this.props;
    const { errMsg, data } = this.state;
    const { settings, user } = store.get();

    const step = getStepHelper(data, { step: stepFromUrl, steps: STEPS, noSkip: true });

    const stepProps = {
      settings,
      onComplete: this.onStepComplete,
      errMsg,
      valetOrder: data,
    };

    switch (step) {
      case 'plan':
        return <PlanStep {...stepProps} />;

      case 'boxes':
        return <BoxesStep {...stepProps} />;

      case 'store':
        return <StoreStep {...stepProps} />;

      case 'address':
        return (
          <AddressStep
            titleItem="orderFlow.address.title"
            user={user}
            {...stepProps}
          />
        );

      case 'time':
        return (
          <TimeStep
            textKey="orderFlow"
            includePromoCode={true}
            {...stepProps}
          />
        );

      case 'account':
        return <AccountStep {...stepProps} />;

      case 'billing':
        return <BillingStep {...stepProps} />;

      case 'summary':
        return <SummaryStep {...stepProps} />;

      default:
        return null;
    }
  }
});


export default withRouter(OrderPage);
