import React from 'react';
import createReactClass from 'create-react-class';
import PropTypes from 'prop-types';

import { WurdText } from 'wurd-react';
import AddressForm from './form';


var AddressStep = createReactClass({

  propTypes: {
    onComplete: PropTypes.func.isRequired,
    titleItem: PropTypes.string.isRequired,
    user: PropTypes.object,
    valetOrder: PropTypes.object
  },

  getInitialState: function () {
    return {
      errMsg: '',
      submitting: false
    }
  },

  render: function () {
    const { valetOrder, onComplete, titleItem, ...props } = this.props;

    return (
      <div className="schedule-step">
        <h2 className="sg-section-title"><WurdText id={titleItem} /></h2>

        <div className="content">
          <div className="form-horizontal">

            <AddressForm
              visit={valetOrder}
              onSubmit={onComplete}
              {...props}
            />

          </div>
        </div>
      </div>
    );
  }

});

export default AddressStep;
