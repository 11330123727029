import api from '../utils/api';
import store from '../store';
import { fetchUserInfo } from '../actions';
import analytics from '../utils/analytics';
import btoa from '../utils/btoa';


export async function sendForgotPasswordEmail(data: object) {
  return api.post('/v1/auth/forgot-password', data);
}

export async function resetPassword(data: object) {
  return api.post('/v1/auth/reset-password', data);
}

export function logout() {
  return api.post('/v1/auth/logout')
    .then(() => {
      localStorage.removeItem('accessToken');

      store.set({ user: null });
    });
}

export async function signin(email: string, password: string) {
  const apiConfig = {
    headers: {
      'Authorization': 'Basic ' + btoa(email + ':' + password),
      'X-Requested-With': 'XMLHttpRequest', // Prevents Safari displaying BasicAuth popup)
    },
  };

  // Login and preload user data
  const data = await api.post('/v1/auth/token?include=valetOrders,items,billingMethod,settings', {}, apiConfig);

  localStorage.setItem('accessToken', data.accessToken);

  return fetchUserInfo();
}

export async function signup(data: { email: string, password: string, marketId: string }) {
  await api.post('/v1/users', data);
  const user = await signin(data.email, data.password);

  analytics.signedUp(user);

  return fetchUserInfo();
}
