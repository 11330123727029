import React from 'react';
import classNames from 'classnames';

import {WurdText} from 'wurd-react';


function LabelField({children, hide, label, help, ...props}) {
  return (
    <div className="form-group" {...props}>
      <label className={classNames({
        'col-sm-3 control-label': true,
        'hide': hide,
        'required': children && children.props.required
      })}>
        <WurdText id={label} />
      </label>
      <div className="col-sm-6">
        {children}
        <div className="text-muted"><WurdText id={help} /></div>
      </div>
    </div>
  );
}

export default LabelField;
