import React from 'react';
import _ from 'underscore';
import { WurdText } from 'wurd-react';

import { setLanguage } from '../actions';
import { get as getLanguage } from '../utils/language';
import store from '../store';

export default function LanguageSwitcher() {
  const { user, settings } = store.get();
  const availableLangs = settings.languages;
  const currentLang = getLanguage();

  // Only display if there are multiple languages options
  if (!availableLangs || availableLangs.length < 2) return null;

  const newLang = _.find(availableLangs, lang => lang !== currentLang);
  return (
    <button
      className="language-switcher"
      onClick={() => {
        setLanguage(newLang, user && user.id);
        // no need to dispatch({language: newLang}) because setLanguage reloads the page
      }}
    >
      <WurdText id={`common.language.${newLang}`} />
    </button>
  );
}
