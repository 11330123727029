import React from 'react';
import createReactClass from 'create-react-class';
import PropTypes from 'prop-types';
import _ from 'underscore';
import { WurdText, WurdMarkdown } from 'wurd-react';

import * as itemHelpers from '../../../utils/item-helpers';

import ItemCounter from '../item-counter';
import Submit from '../../form/submit';


var StoreStep = createReactClass({

  propTypes: {
    settings: PropTypes.object.isRequired,
    onComplete: PropTypes.func.isRequired
  },

  componentDidUpdate(prevProps) {
    if (this.props.productCounts !== prevProps.productCounts) {
      this.setState(this.getState());
    };
  },

  getInitialState() {
    return this.getState();
  },

  getState() {
    var { settings, valetOrder: { productCounts = {}, storeItems = [] } = {} } = this.props;

    var state = {
      errMsg: '',
      submitting: false
    }

    const sItems = storeItems.reduce((o, { id, quantity }) => ({ ...o, [id]: quantity }), {});

    _.each(settings.products, function (product) {
      state[product.id] = productCounts[product.id] || sItems[product.id] || product.quantity || 0;
    });

    return state;
  },

  render() {
    var { settings } = this.props;
    var state = this.state;

    return (
      <div className="schedule-step">
        <h2 className="sg-section-title"><WurdText id="storefront.title" /></h2>

        <div className="content">
          <WurdMarkdown id="storefront.intro" />

          {_.map(settings.products, (item, index) => {
            var type = item.slug;
            if (!type) return null;

            var itemId = item.id;

            var getLang = itemHelpers.getLangValue.bind(null, item);

            //If maxQuantity is 0, treat the item as hidden from the ordering process
            if (item.quantity === 0 && item.maxQuantity === 0) return null;

            return (
              <ItemCounter
                key={type}
                type={type}
                price={getLang('displayPrice')}
                count={state[itemId] || 0}
                onChange={_.partial(this.onItemCounterChange, itemId)}
                title={getLang('title')}
                desc={getLang('info')}
                image={item.image}
                first={index === 0}
                maxCount={item.maxQuantity}
              />
            );
          })}

          <Submit
            submitting={state.submitting}
            errMsg={state.errMsg}
            text="common.buttons.next"
            onClick={this.onSubmit}
          />
        </div>
      </div>
    );
  },

  /**
   * Returns collected data for entire form
   *
   * @return {Object}
   */
  getValue() {
    var { settings } = this.props;
    var state = this.state;

    var products = [];
    _.each(settings.products, function (itemConfig) {
      var count = state[itemConfig.id];

      if (count) {
        products.push({
          id: itemConfig.id,
          quantity: count
        });
      }
    });

    var data = {
      storeItems: products
    };

    return data;
  },

  onSubmit(event) {
    if (event) event.preventDefault();

    this.props.onComplete(this.getValue());
  },

  /**
   * Updates the state (count) for the given item counter
   *
   * @param {String} type     'box', 'bulky' etc.
   * @param {Number} count
   */
  onItemCounterChange(productId, count) {
    /*var changes = {};
    changes[productId] = count;

    this.setState(changes);*/

    this.setState({
      [productId]: count
    });
  }
});

export default StoreStep;
