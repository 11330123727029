import api from '../utils/api';
import store from '../store';
import * as itemHelpers from '../utils/item-helpers';
const loadImage = require('blueimp-load-image');
require('blueimp-canvas-to-blob'); // polyfill for canvas.toBlob

// a mix of Item model and ItemConfig from settings (has image for example), todo improvedataURLtoBlob
export interface Item {
  id: string;
  state: string;
  type: string;
  images: Array<string>;
  image: string;
  title: string;
  _isNew: boolean;
}



export async function fetchItems() {
  const items = await api.get('/v1/items');
  store.set({ items });
  return items;
}

export async function updateItem(itemId: string, changes: object) {
  const { items }: { items: Array<Item> } = store.get();

  const item = items.find(item => item.id === itemId);

  if (!item) { // we shoudn't be in this situation, even temporary items are in items array
    throw new Error(`unknown item ${itemId}, changes ${JSON.stringify(changes)}`);
  }

  // Post to server if this is a temporary item (e.g. bulky item created but not yet saved)
  if (item._isNew) {
    const attrs = { ...changes, type: item.type, state: item.state };

    const newItem = await api.post('/v1/items', attrs);

    item._isNew = false;
    itemHelpers.upsertItem({ id: itemId, ...newItem });

    return newItem;
  }

  // This is an existing item in DB save it first
  else {
    // Optimistically update locally
    itemHelpers.upsertItem({ id: itemId, ...changes });

    // Update on the server
    const newItem = await api.put('/v1/items/' + itemId, changes);

    itemHelpers.upsertItem({ id: itemId, ...newItem });

    return newItem;
  }
};

export async function deleteBulkyItem(itemId: string) {
  try {
    await api.delete(`/v1/items/${itemId}`);
  } catch (err) {
    if (err.status !== 404) { // ignore 404, it means user deleted an item not saved yet
      store.set({ error: err.message })
    }
  }

  const { items }: { items: Array<Item> } = store.get();

  store.set({ items: items.filter(item => item.id !== itemId) });
}

export function addItemImage(itemId: string, file: File) {
  const { items }: { items: Array<Item> } = store.get();

  // Load and resize images before sending
  const loadImageOptions = {
    canvas: true,
    maxWidth: 1200,
    maxHeight: 1200
  };

  return new Promise((resolve, reject) => {
    loadImage(file, (canvas: any) => {
      if (canvas.type === 'error') {
        return reject(new Error('Error loading image'));
      }

      //NOTE: Requires the canvas.toBlob polyfill (https://github.com/blueimp/JavaScript-Canvas-to-Blob)
      canvas.toBlob((blob: Blob) => {
        const formData = new FormData();

        formData.append('file', blob, 'photo.jpg');

        const xhrConfig = {
          transformRequest: (data: any) => data
        };

        // Upload
        resolve(api.post(`/v1/items/${itemId}/images`, formData, xhrConfig)
          .then((image: any) => {
            // Update locally

            itemHelpers.upsertItem({ id: itemId, images: [image] });
          }));
      }, 'image/jpeg');
    }, loadImageOptions);
  });
}

export function deleteItemImage(itemId: string, imageId: string) {
  // Update optimistically
  itemHelpers.upsertItem({ id: itemId, images: [] });

  const ac = new AbortController();
  setTimeout(() => ac.abort(), 8000); // timeout after 8s
  return api.delete(`/v1/items/${itemId}/images/${imageId}`, { signal: ac.signal });
}
