import React from 'react';
import createReactClass from 'create-react-class';
import { withRouter } from 'react-router-dom';
import { WurdText, WurdMarkdown } from 'wurd-react';

import { scheduleValetOrder, rescheduleValetOrder } from '../../actions';
import * as visitHelpers from '../../utils/visit-helpers';
import getStepHelper, { stepsGetError } from '../../utils/order-helpers';
import store from '../../store';

import BoxesStep from './boxes';
import StoreStep from './store';
import AddressStep from './address';
import TimeStep from './time';
import ItemsStep from './items';
import BillingStep from './billing';
import SummaryStep from './summary';


const stepsConfig = {
  steps: [
    'boxes',
    'store',
    'deliver-items',
    'collect-items',
    'address',
    'time',
    'billing',
    //'summary',
  ],
  mode: 'schedule',
};

/**
 * convert a step name e.g. 'address', 'time' to a pathname e.g; /reschedule/ABC/time or /schedule/time
 * @param {string} curStep
 * @param {object} props
 */
function getStepPath(curStep, { match: { params: { valetOrderSid } }, location }) {
  const parts = location.pathname.slice(1).split('/');

  // we need to keep the prefix /schedule or /reschedule/valetOrderSid, so 1 or 2 parts
  const basePath = parts.slice(0, valetOrderSid ? 2 : 1).join('/');
  return `/${basePath}/${curStep}${location.search}`;
}

var SchedulePage = createReactClass({
  getInitialState() {
    return {
      errMsg: '',
      IsSubmitting: false,
      data: {},
    };
  },

  componentDidMount() {
    this.init();
    // store.onChange((storeData, oldStoreData) => {
    //   if (!oldStoreData.valetOrders && storeData.valetOrders) {
    //     this.init();
    //   }
    // });
  },

  componentDidUpdate({ match: { params: oldParams } }) {
    const { match: { params } } = this.props;
    if (params?.valetOrderSid && params.valetOrderSid !== oldParams.valetOrderSid) {
      this.init();
    }
  },

  init() {
    const { match: { params: { valetOrderSid: sid } = {} } } = this.props;
    const { valetOrders } = store.get();

    // If a valetOrder sid is available from the url, the data will be used (rescheduling flow)
    const data = sid && valetOrders.find(o => o.sid === sid.toLowerCase());

    const step = getStepHelper(data, stepsConfig);

    this.props.history.replace(getStepPath(step, this.props));

    if (data) {
      this.setState({ data: { ...data, bulkyCounts: {} } }); // reset bulkyCounts, because it's used for adding extra bulkies, they can be removed from collect-items step
    } else if (sid) {
      this.setState({ errMsg: `Order ${sid} not found` });
    }
  },

  render() {
    const { data } = this.state;

    if (data?.id && !visitHelpers.canReschedule(data)) { // if data.id, the valetOrder was initialized from url (see componentDidMount)
      return (
        <div>
          <div className="header">
            <h3 className="sg-section-title"><WurdText id="reschedule.tooLate.title" /></h3>
          </div>
          <WurdMarkdown id="reschedule.tooLate.text" />
        </div>
      );
    }

    return (
      <div>
        {data?.id &&  (
          <div className="alert alert-info">
            <WurdMarkdown
              id="reschedule.editingHeader.title"
              vars={{
                orderId: data.sid.toUpperCase(),
              }}
            />
          </div>
        )}

        {this.renderStep()}
      </div>
    );
  },

  renderStep() {
    const { match: { params: { step: stepFromUrl } } } = this.props;
    const { isSubmitting, errMsg, data } = this.state;

    const { user, settings } = store.get();

    const step = getStepHelper(data, { step: stepFromUrl, noSkip: true, ...stepsConfig });

    const stepProps = {
      settings,
      onComplete: this.onStepComplete,
      valetOrder: data,
      isSubmitting,
      errMsg
    };

    switch (step) {
      case 'boxes':
        return <BoxesStep skipValidation {...stepProps} />;

      case 'store':
        return <StoreStep {...stepProps} />;

      case 'address':
        return (
          <AddressStep
            titleItem="scheduleFlow.address.title"
            user={user}
            {...stepProps}
          />
        );

      case 'time':
        return (
          <TimeStep
            textKey="scheduleFlow"
            {...stepProps}
          />
        );

      case 'deliver-items':
        return <ItemsStep kind="deliver" {...stepProps} />;

      case 'collect-items':
        return <ItemsStep kind="collect" {...stepProps} />;

      case 'billing':
        return <BillingStep {...stepProps} />;

      case 'summary':
        return <SummaryStep {...stepProps} />

      default:
        throw new Error('Step not found: ' + step);
    }
  },

  onStepComplete(stepData) {
    const { data } = this.state;
    const { match: { params: { step } } } = this.props;

    const newData = { ...data, ...stepData };

    const newStep = getStepHelper(newData, { step, ...stepsConfig });

    if (!newStep) { // we're done, no more step
      this.submit(newData);
    } else if (newStep === step) {
      this.setState({
        errMsg: stepsGetError[step] && stepsGetError[step](newData),
        data: newData,
      });
    } else {
      this.setState({ data: newData }, () => {
        this.props.history.push(getStepPath(newStep, this.props));
        window.scrollTo(0, 0);
      });
    }
  },

  async submit(data) {
    this.setState({ isSubmitting: true });

    try {
      if (data.id) { // if data.id, the valetOrder was initialized from url (see componentDidMount)
        await rescheduleValetOrder(data);
      } else {
        await scheduleValetOrder(data);
      }

      this.props.history.push('/items-user');
    } catch (err) {
      this.setState({
        isSubmitting: false,
        errMsg: err.message,
      });
      this.props.history.push(getStepPath('time', this.props));
    }
  },
});


export default withRouter(SchedulePage);
