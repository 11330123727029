import React from 'react';
import { Link } from 'react-router-dom';

import { WurdText } from 'wurd-react';
import LoginForm from './login-form';
import { getMarket } from '../../utils/api';


export default function LoginPage() {
  return (
    <div>
      <h2 className="login-title"><WurdText id="login.title" /></h2>

      <LoginForm
        onComplete={() => {
          // If a marketIdOrname is detected from url, we can clear that for clarity, in case it's not the same market
          // because users are associated with a market, received from user.settings, which overrides the response from /settings?market=marketIdOrname
          // don't do that in login-form or utils/state, it's used by the order-flow, no hard-redirect should been done there
          const marketIdOrName = getMarket();

          if (marketIdOrName) {
            const url = new URL(window.location.href);
            url.searchParams.delete('market');
            url.pathname = '/';
            window.location.href = url;
          }
        }}
      />

      <div className="footer" style={{ display: 'flex', justifyContent: 'space-around' }}>
        <Link to="/signup"><WurdText id="login.signUp" /></Link>

        <Link to="/get-started"><WurdText id="login.getStarted" /></Link>

        <Link to="/forgot-password"><WurdText id="login.forgotPassword" /></Link>
      </div>
    </div>
  );
}
