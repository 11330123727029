import React from 'react';
import createReactClass from 'create-react-class';
import {withRouter, Link} from 'react-router-dom';

import {resetPassword} from '../../actions';
import wurd, {WurdText} from 'wurd-react';

import {Form, Field, Input} from '../form';


var ResetPasswordPage = createReactClass({
  getInitialState: function() {
    return {
      isSubmitting: false,
      success: false,
      error: null
    };
  },

  onSubmit: async function(data) {
    const {location} = this.props;
    const sp = new URLSearchParams(location.search);
    data.token = sp.get('token');
    
    this.setState({ isSubmitting: true });

    try {
      await resetPassword(data);
      this.setState({
        success: true,
        error: null,
        isSubmitting: false
      });
    } catch(err) {
      this.setState({
        isSubmitting: false,
        error: err
      });
    }
  },

  render: function() {
    var state = this.state;

    return (
      <div>
        <h2 className="title"><WurdText id="resetPassword.title" /></h2>

        {(state.success) ? this.renderSuccessMsg() : this.renderForm() }

        <hr />

        <div className="sg-auth-extra">
          <Link to="/login"><WurdText id="resetPassword.login" /></Link>
        </div>
      </div>
    );
  },

  renderSuccessMsg: function() {
    return (
      <div className="alert alert-success">
        {wurd.map('resetPassword.success', (item, i) => <p key={i}>{item.text()}</p>)}
      </div>
    );
  },

  renderForm: function() {
    var state = this.state;

    return (
      <Form 
        submitText="resetPassword.submit" 
        onSubmit={this.onSubmit} 
        isSubmitting={state.isSubmitting}
        error={state.error}
      >
        <Field icon="envelope">
          <Input 
            placeholder="resetPassword.email"
            type="email"
            name="email" 
            required
            autoFocus
          />
        </Field>

        <Field icon="lock">
          <Input 
            placeholder="resetPassword.password"
            type="password"
            name="password"
            validate={e =>
              e.target.value.length < 7 ? wurd.text('signup.errors.passwordTooShort', { min: 7 }) : ''
            }
            required
          />
        </Field>

        <Field icon="lock">
          <Input 
            placeholder="resetPassword.confirmPassword" 
            type="password"
            name="confirmPassword"
            validate={e =>
              e.target.value !== e.target.form.elements.password.value
                ? wurd.text('signup.errors.passwordMismatch')
                : ''
            }
            required
          />
        </Field>
      </Form>
    );
  }
});

export default withRouter(ResetPasswordPage);
