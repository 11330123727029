import api from '../utils/api';
import { fetchItems } from './items';
import * as itemHelpers from '../utils/item-helpers';
import * as visitHelpers from '../utils/visit-helpers';
import analytics from '../utils/analytics';
import store from '../store';

export interface ValetOrder {
  id: string;
  deliverItems: Array<string>;
  collectItems: Array<string>;
  collect: 'immediately' | 'later';
  date: string;
  timeslot: string;
  collectDate: string;
  collectTimeslot: string;
}

export async function fetchValetOrders() {
  const valetOrders = await api.get('/v1/valet/orders');
  return valetOrders.sort((a: { date: string }, b: { date: string }) => a.date.localeCompare(b.date));
};

export async function orderBoxes(valetOrder: ValetOrder) {
  await api.post('/v1/valet/orders', valetOrder);

  // Update
  const [valetOrders, items] = await Promise.all([
    fetchValetOrders(),
    fetchItems(),
  ]);

  store.set({ valetOrders, items });

  analytics.createdValetOrder(valetOrder);
}

export async function scheduleValetOrder(valetOrder: ValetOrder) {
  // Add valetOrders selected to deliver/collect
  valetOrder.deliverItems = itemHelpers.getItemsSelectedToDeliver();
  valetOrder.collectItems = itemHelpers.getItemsSelectedToCollect();

  // ensure collect, collectDate, collectTimeslot are set
  if (!valetOrder.collect || valetOrder.collect === 'immediately') {
    valetOrder.collect = 'immediately';
    valetOrder.collectDate = valetOrder.date;
    valetOrder.collectTimeslot = valetOrder.timeslot;
  }

  // Create
  await api.post('/v1/valet/orders', valetOrder);

  // Get updated valetOrders
  // Update items to load the associated valetOrders; this indicates when they are selected for delivery/collection
  const [valetOrders, items] = await Promise.all([
    fetchValetOrders(),
    fetchItems()
  ]);
  store.set({ valetOrders, items, selectedItems: [] });
};

export async function rescheduleValetOrder(data: { id: string, productCounts: object, storeItems?: Array<{ id: string, quantity: number }> }) {
  if (data.storeItems) {
    data.productCounts = data.storeItems.reduce((memo, storeItem) => {
      memo[storeItem.id] = storeItem.quantity;

      return memo;
    }, {} as any);
  }

  await api.put(`/v1/valet/orders/${data.id}`, data);

  // Get updated valetOrders
  // Update items to load the associated valetOrders; this indicates when they are selected for delivery/collection
  const [valetOrders, items] = await Promise.all([
    fetchValetOrders(),
    fetchItems()
  ]);
  store.set({ valetOrders, items, selectedItems: [] });
}

export async function cancelValetOrder(id: string) {
  await api.put(`/v1/valet/orders/${id}/cancel`);

  // Get updated valetOrders
  // Update items to load the associated valetOrders; this indicates when they are selected for delivery/collection
  const [valetOrders, items] = await Promise.all([
    fetchValetOrders(),
    fetchItems()
  ]);
  store.set({ valetOrders, items, selectedItems: [] });
}
