import React from 'react';
import createReactClass from 'create-react-class';
import PropTypes from 'prop-types';

import {WurdText} from 'wurd-react';
import SignupForm from '../../auth/signup-form';
import LoginForm from '../../auth/login-form';


var AccountStep = createReactClass({

  propTypes: {
    onComplete: PropTypes.func.isRequired,
    promoCode: PropTypes.string
  },

  getInitialState() {
    return {
      mode: 'signup'
    }
  },

  render() {
    const {promoCode, onComplete} = this.props;
    const mode = this.state.mode;

    const wurdSection = `orderFlow.account.${mode}`;

    const form = (mode === 'signup')
      ? <SignupForm onComplete={onComplete} promoCode={promoCode} />
      : <LoginForm onComplete={onComplete} />;

    return (
      <div className="schedule-step">
        <h2 className="sg-section-title"><WurdText id={`${wurdSection}.title`} /></h2>

        {form}

        <div className="text-center" style={{marginTop: 40, fontSize: '1.2em'}}>
          <button 
            type="button" 
            className="btn-link text-center" 
            onClick={this.toggleMode}
          >
            <WurdText id={`${wurdSection}.switch`} />
          </button>
        </div>
      </div>
    );
  },

  toggleMode() {
    var mode = this.state.mode;

    var newMode = (mode === 'signup') ? 'login' : 'signup';

    this.setState({
      mode: newMode
    });
  }
  
});

export default AccountStep;
