import React from 'react';
import { WurdText } from 'wurd-react';

function InvoiceHeader() {
  const styles = { color: '#999', textAlign: 'center', lineHeight: '24px' }

  return (
    <tr>
      <td></td>
      <td style={styles}><WurdText id="invoiceDetails.headerPrice" /></td>
      <td style={styles}><WurdText id="invoiceDetails.headerTax" /></td>
      <td style={styles}><WurdText id="invoiceDetails.headerTotal" /></td>
    </tr>
  );
}

export default InvoiceHeader;
