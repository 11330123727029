import React from 'react';
import {withRouter, Link} from 'react-router-dom';

import {sendForgotPasswordEmail} from '../../actions';
import wurd, {WurdText} from 'wurd-react';

import {Form, Field, Input} from '../form';


class ForgotPasswordPage extends React.Component {
  state = {
    isSubmitting: false,
    success: false,
    errMsg: null
  };  

  onSubmit = async (data) => {
    this.setState({ isSubmitting: true });

    try {
      await sendForgotPasswordEmail(data);
      this.setState({
        success: true,
        errMsg: null,
        isSubmitting: false
      });
    } catch(err) {
      this.setState({
        isSubmitting: false,
        errMsg: err.message
      });
    }
  }

  render() {
    var state = this.state;

    return (
      <div>
        <h2><WurdText id="forgotPassword.title" /></h2>

        {state.success ? this.renderSuccessMsg() : this.renderForm() }

        <div className="footer">
          <Link to="/login"><WurdText id="forgotPassword.login" /></Link>
        </div>
      </div>
    );
  }

  renderSuccessMsg() {
    return (
      <div className="alert alert-success">
        {wurd.map('forgotPassword.success', (item, i) => <p key={i}>{item.text()}</p>)}
      </div>
    );
  }

  renderForm() {
    const {isSubmitting, errMsg} = this.state;

    return (
      <Form 
        submitText="forgotPassword.submit"
        onSubmit={this.onSubmit} 
        isSubmitting={isSubmitting}
        errMsg={errMsg}
      >
        <Field icon="envelope">
          <Input 
            placeholder="forgotPassword.email" 
            type="email"
            name="email"
            required
            autoFocus
          />
        </Field>
      </Form>
    );
  }
}

export default withRouter(ForgotPasswordPage);
