import wurd from 'wurd-react';

const apiUrl = process.env.REACT_APP_API_URL || '/api';

// returns true for plain object literals, like {foo: 'bar'}, false for other cases, like instances of classes
const isPlainObject = obj => Object.getPrototypeOf(obj) === Object.prototype || Object.getPrototypeOf(obj) === null;

export const getMarket = () => {
  const url = new URL(window.location.href);

  const path = url.pathname.slice('/valet/'.length).replace(/\/$/, '');

  return path || url.searchParams.get('market');
};

export function fetchJson(url, { body, headers, ...o } = {}) {
  const isJson = body && isPlainObject(body); // most of the time we send plain 'json' objects
  return fetch(url[0] === '/' ? apiUrl + url : url, {
    headers: {
      ...localStorage.getItem('accessToken') && { 'Authorization': 'Bearer ' + localStorage.getItem('accessToken') },
      ...isJson && { 'Content-Type': 'application/json' },
      ...headers
    },
    body: isJson ? JSON.stringify(body) : body,
    ...o
  }).then(async r => {
    const data = await (/^application\/json/.test(r.headers.get('content-type')) ? r.json() : r.text());
    if (r.ok) return data;

    const {
      message = r.status === 403 ? 'Forbidden' : r.status === 0 ? wurd.text('common:errors.notConnected') : wurd.text('common:errors.generic'),
      type,
      name = type
    } = data?.error || {};
    const key = 'apiErrors:' + name;
    const msg = wurd.text(key);

    const errors = data?.error?.errors && Object.entries(data.error.errors).reduce((memo, [field, error]) => {
      return {
        ...memo,
        [field]: Array.isArray(error)
          ? error.map(e => e.message).join(', ')
          : error.message || error.type || error || 'invalid',
      };
    }, {});

    const err = new Error(msg && msg !== key ? wurd.text('apiErrors:' + name) : message);
    err.status = r.status;
    err.errors = errors || [];
    throw err;
  });
}

export default {
  get: (url, opts) => fetchJson(url, { method: 'GET', ...opts }),
  post: (url, body, opts) => fetchJson(url, { method: 'POST', body, ...opts }),
  put: (url, body, opts) => fetchJson(url, { method: 'PUT', body, ...opts }),
  delete: (url, opts) => fetchJson(url, { method: 'DELETE', ...opts }),
};
