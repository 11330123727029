import React from 'react';
import _ from 'underscore';
import { Link } from 'react-router-dom';

import { WurdText } from 'wurd-react';
import ItemList from './item-list.react';
import NextVisit from './items-next-visit.react';
import ItemsStorageEmpty from './items-storage-empty.react';

import store from '../store';
import * as itemHelpers from '../utils/item-helpers';
import * as visitHelpers from '../utils/visit-helpers';
import * as ui from '../utils/ui';


export default function ItemsStoragePage() {
  const { selectedItems, visits, valetOrders } = store.get();

  const itemsWithUser = itemHelpers.getItemsWithUser();
  const itemsInStorage = itemHelpers.getItemsInStorage();
  const nextDelivery = visitHelpers.getNextDelivery(visits);
  const nextCollection = visitHelpers.getNextCollection(visits);

  const itemsByOrder = _.groupBy(itemsInStorage, item => itemHelpers.getOrder(item)?.id);

  const ordersWithItems = valetOrders.map((order) => {
    const items = itemsByOrder[order.id];
    if (!items || !items.length) return null;

    return {
      order,
      items,
    };
  }).filter(Boolean);

  const itemsWithoutOrder = itemsByOrder[undefined] || null;

  const itemsWithUserLink = (
    <div className="item-state-switcher">
      <Link to="/items-user"><WurdText id="items.inStorage.viewWithUser" /></Link>
    </div>
  );

  return (
    <div>
      <div className="page-head page-head-small">
        <h3 className="sg-page-title"><WurdText id="items.inStorage.title" /></h3>
      </div>

      <div className="cl-mcont">
        {valetOrders.map(order => (
          <NextVisit visit={order} key={order.id} />
        ))}

        {ordersWithItems.map(({ order, items }) => (
          <div key={order.id} style={{ margin: '40px 0' }}>
            <h4><WurdText id="items.inStorage.orderGroup.title" vars={{ date: ui.date(order.nextDate) }} /></h4>
            <ItemList items={items} selectedItems={selectedItems} />
          </div>
        ))}

        {itemsWithoutOrder && (
          <>
            <h4><WurdText id="items.inStorage.orderGroup.others" /></h4>
            <ItemList items={itemsWithoutOrder} selectedItems={selectedItems} />
          </>
        )}

        {
          (!itemsInStorage.length)
            ? <ItemsStorageEmpty
              itemsWithUser={itemsWithUser}
              nextDelivery={nextDelivery}
              nextCollection={nextCollection}
            />
            : null
        }

        {(itemsWithUser.length) ? itemsWithUserLink : null}
      </div>
    </div>
  );
}
