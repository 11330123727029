import _ from 'underscore';
import store from '../store';


export const isNewUser = () => {
  const { items, visits } = store.get();
  // Check if an item or visit exists
  const hasItems = !!_.size(items);
  const hasVisits = !!_.size(visits);

  return !hasItems && !hasVisits;
};

export const hasBillingMethod = function (billingMethod, user) {
  const userBillingMethod = user && user.billingMethod;

  // If auto billing not supported by the customer then OK, all done
  if (!billingMethod) {
    return true;
  }

  // If this particular user has 'invoice' then they don't require the billing setup
  else if (userBillingMethod === 'invoice') {
    return true;
  }

  // Otherwise billing method must match the defined billing method
  else {
    return userBillingMethod === billingMethod;
  }
};
