import store from '../store';
import api from '../utils/api';
import analytics from '../utils/analytics';


export function fetchUserInfo() {
  return api.get('/v1/user?include=valetOrders,items,billingMethod,settings')
    .then(user => {
      const { valetOrders, items, settings: userSettings, ...userData } = user;

      store.set({
        settings: userSettings,
        user: userData,
        valetOrders: valetOrders.sort((a: { date: string }, b: { date: string }) => a.date.localeCompare(b.date)),
        items,
      });

      analytics.identify(user);

      return user;
    });
}

export async function updateProfile(userId: string, data: object) {
  await api.put('/v1/user', data);

  return fetchUserInfo();
}
