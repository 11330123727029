import React from 'react';
import createReactClass from 'create-react-class';
import wurd from 'wurd-react';
import PropTypes from 'prop-types';

import GetStarted from './items-get-started.react';


var ItemsStorageEmpty = createReactClass({

  propTypes: {
    itemsWithUser: PropTypes.array,
    nextDelivery: PropTypes.object,
    nextCollection: PropTypes.object
  },

  render: function() {
    return (
      <div>
        <div className="no-results">
          <div className="row">
            <div className="col-sm-offset-2 col-sm-8 col-md-offset-3 col-md-6">
                
              { this.renderContent() }

            </div>
          </div>
        </div>
      </div>
    );
  },

  renderContent: function() {
    var props = this.props;

    var hasItemsWithUser = !!props.itemsWithUser.length;
    var nextDelivery = props.nextDelivery;
    var nextCollection = props.nextCollection;

    //New user
    if (!hasItemsWithUser && !nextDelivery) {
      return <GetStarted />
    }

    //Has items at home
    if (hasItemsWithUser && !nextCollection) {
      return wurd.map('items.inStorage.noResults.hasItemsWithUser', (item, i) => <p key={i}>{item.text()}</p>);
    }

    //Generic no results message
    return wurd.map('items.inStorage.noResults.general', (item, i) => <p key={i}>{item.text()}</p>);
  }

});

export default ItemsStorageEmpty;
