import React from 'react';
import { Collapse } from 'react-bootstrap';
import { useLocation, useHistory, Link } from 'react-router-dom';
import { WurdText } from 'wurd-react';

import store from '../store';


export default function SelectionBar() {
  const location = useLocation();
  const history = useHistory();
  const { selectedItems } = store.get();

  return (
    <Collapse in={selectedItems.length > 0} timeout={600} appear>
      <div className="selection-bar navbar navbar-default navbar-fixed-top">
        <div className="container-fluid">
          <div className="btn-group pull-right">
            <button
              type="button"
              className="btn btn-success btn-flat"
              onClick={() => {
                store.set({ selectedItems: [] });
                if (/\/schedule/.test(location.pathname)) {
                  history.push('/');
                }
              }}
            >
              <i className="fa fa-times"></i> <WurdText id="items.selectionBar.cancel" />
            </button>
            <Link to={`/schedule`} className="btn btn-success btn-flat">
              <i className="fa fa-truck"></i> <WurdText id="items.selectionBar.schedule" />
            </Link>
          </div>

          <h4 className="text single-line">
            <i className="fa fa-check"></i> <WurdText id="items.selectionBar.count" vars={{ number: selectedItems.length }} />
          </h4>
        </div>
      </div>
    </Collapse>
  );
}
