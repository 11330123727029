import React from 'react';
import createReactClass from 'create-react-class';
import PropTypes from 'prop-types';

import * as language from '../../../utils/language';

import Form from '../../form/form';
import Field from '../../form/label-field';
import Input from '../../form/input';
import AreaSelect from './area-select';

var ScheduleAddressForm = createReactClass({

  propTypes: {
    settings: PropTypes.object.isRequired,
    user: PropTypes.object,
    visit: PropTypes.object,
    onSubmit: PropTypes.func
  },

  render: function() {
    const {
      settings,
      user = {},
      visit: {
        phone = user && user.phone, // the = {} default destructuring fails for user===null, null sucks
        address = user && user.address,
        area = user && user.area,
        instructions,
        altPhone
      },
      ...props
    } = this.props;

    return (
      <Form 
        onSubmit={this.onSubmit}
        submitText="common.buttons.next" 
        {...props}
      >
        <Field 
          label="addressForm.phone.label" 
          help="addressForm.phone.help" 
        >
          <Input 
            name="phone"
            type="text" 
            defaultValue={phone}
            required
          />
        </Field>

        <Field 
          label="addressForm.address.label" 
          help="addressForm.address.help" 
        >
          <Input 
            name="address" 
            component="textarea"
            defaultValue={address}
            required
          />
        </Field>

        <Field 
          label="addressForm.area.label" 
          help="addressForm.area.help" 
        >
          {language.get() && <AreaSelect 
            name="area" 
            settings={settings}
            areas={settings.areas || []}
            lang={language.get()}
            defaultValue={area}
            required
          />}
        </Field>

        <Field 
          label="addressForm.instructions.label" 
          help="addressForm.instructions.help" 
        >
          <Input 
            name="instructions" 
            component="textarea"
            defaultValue={instructions}
            placeholder="addressForm.instructions.placeholder" 
          />
        </Field>

        <Field 
          label="addressForm.altPhone.label" 
          help="addressForm.altPhone.help" 
        >
          <Input 
            name="altPhone"
            type="text"
            defaultValue={altPhone}
          />
        </Field>
      </Form>
    );
  },

  onSubmit: function(data) {
    this.props.onSubmit(data);
  }

});

export default ScheduleAddressForm;
