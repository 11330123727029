import moment from 'moment';

/**
 * A wrapper around the Wurd client which adds the language code to the requested pages
 */

export let currentLang;

/**
 * Loads content from the cache
 *
 * @param {String} langCode
 * @return {Object}
 */
export function get() {
  return currentLang;
};

/**
 * Detects the current language
 */
export async function init(availableLanguages = ['en']) {
  const detectedLang = detectQueryStringLanguage() // First get from query string
    || window.localStorage.getItem('lang') // Then localStorage
    || detectBrowserLanguage(); // Fall back to browser setting

  const _baseLang = detectedLang.split('-', 1)[0];
  const baseLang = { nb: 'no' }[_baseLang] || _baseLang; // convert 'nb' to 'no' as we use 'no' in our availableLanguages settings for Norsk

  // Ensure in list of supported languages or check if there is content for a non-region specific language, fallback to default
  currentLang = [detectedLang, baseLang].find(l => availableLanguages.includes(l)) || availableLanguages[0];

  switch (currentLang) {
    case 'da': return import('moment/locale/da');
    case 'de': return import('moment/locale/de');
    case 'es': return import('moment/locale/es');
    case 'fr': return import('moment/locale/fr');
    case 'ja': return import('moment/locale/ja');
    case 'ko': return import('moment/locale/ko');
    case 'nl': return import('moment/locale/nl');
    case 'pt': return import('moment/locale/pt');
    case 'zh': return import('moment/locale/zh-cn');
    case 'no': return import('moment/locale/nb');
    case 'sv': return import('moment/locale/sv');
  }
}


/**
 * @return {String}
 */
function detectQueryStringLanguage() {
  if (typeof window === 'undefined') return;

  const params = new URLSearchParams(window.location.search);
  return params.get('lang');
}

/**
 * @return {String}
 */
function detectBrowserLanguage() {
  var userLngChoices = [];

  //Detect from browser settings
  if (typeof navigator !== 'undefined') {
    if (navigator.languages) { // chrome only; not an array, so can't use .push.apply instead of iterating
      for (var i = 0; i < navigator.languages.length; i++) {
        userLngChoices.push(navigator.languages[i]);
      }
    }

    if (navigator.userLanguage) {
      userLngChoices.push(navigator.userLanguage);
    }

    if (navigator.language) {
      userLngChoices.push(navigator.language);
    }
  }

  return userLngChoices[0];
}
