import React from 'react';
import moment from 'moment';
import wurd from 'wurd-react';

import * as visitHelpers from '../../../utils/visit-helpers';

export default function OrderInfo({ cms, order, settings }) {
  const dateFormat = wurd.text('timeForm.date.dateFormat');

  return (
    <>
      <div className="row">
        <label className="col-sm-3 control-label"><cms.Text id="address" /></label>
        <div className="col-md-3"><p>{order.address}</p></div>
      </div>

      <div className="row">
        <label className="col-sm-3 control-label"><cms.Text id="phone" /></label>
        <div className="col-md-3"><p>{order.phone}</p> </div>
      </div>

      {order.promoCode && (
        <div className="row">
          <label className="col-sm-3 control-label"><cms.Text id="promoCode" /></label>
          <div className="col-md-3"><p>{order.promoCode}</p> </div>
        </div>
      )}

      {order.collectDate && (
        <div className="row">
          <label className="col-sm-3 control-label"><cms.Text id="deliverDate" /></label>
          <div className="col-md-5">
            <p>{moment(order.date).format(dateFormat)} {visitHelpers.formatTimeslot(order.timeslot, settings.dateFormats?.time)}</p>
          </div>
        </div>
      )}

      {order.collectDate ? (
        <div className="row">
          <label className="col-sm-3 control-label"><cms.Text id="collectDate" /></label>
          <div className="col-md-5"><p>{moment(order.collectDate).format(dateFormat)} {visitHelpers.formatTimeslot(order.collectTimeslot, settings.dateFormats?.time)}</p></div>
        </div>
      ) : (
        <div className="row">
          <label className="col-sm-3 control-label"><cms.Text id="collectDate" /></label>
          <div className="col-md-5"><p style={{ float: "left" }}>{moment(order.date).format(dateFormat)} {visitHelpers.formatTimeslot(order.timeslot, settings.dateFormats?.time)}</p></div>
        </div>
      )}
    </>
  );
}
