import React from 'react';
import { useLocation, Link } from 'react-router-dom';
import LanguageSwitcher from './language-switcher';
import store from '../store';


export default function LoggedOutLayout({ children }) {
  const { settings } = store.get();
  const location = useLocation();

  return (
    <div className={`logged-out ${/\/get-started/.test(location.pathname) ? 'get-started-page' : ''}`}>
      <LanguageSwitcher />

      <div className="middle-block">
        <div className="middle-block__content">
          <section className="card">
            <h1 className="brand sg-navbar">
              <Link to="/login">
                <img src={settings.brand && settings.brand.logo} alt={settings.brand && settings.brand.name} />
              </Link>
            </h1>

            <div className="card__content">
              {children}
            </div>
          </section>
        </div>
      </div>
    </div>
  );
}
