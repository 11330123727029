/* eslint-disable jsx-a11y/anchor-is-valid */

import React from 'react';
import createReactClass from 'create-react-class';
import { withRouter, Link } from 'react-router-dom';
import classNames from 'classnames';

import { logout } from '../actions';
import { WurdText } from 'wurd-react';
import store from '../store';


var Header = createReactClass({
  getInitialState: function () {
    return {
      menuCollapsed: true,
      dropdownCollapsed: true
    };
  },

  toggleMenu: function () {
    this.setState({
      menuCollapsed: !this.state.menuCollapsed
    });
  },

  toggleDropdown: function (event) {
    event.preventDefault();

    this.setState({
      dropdownCollapsed: !this.state.dropdownCollapsed
    });
  },

  closeMenu: function () {
    this.setState({
      menuCollapsed: true,
      dropdownCollapsed: true
    });
  },

  render: function () {
    const { menuCollapsed, dropdownCollapsed } = this.state;
    const { settings, user } = store.get();

    return (
      <div id="head-nav" className="sg-navbar navbar navbar-default navbar-fixed-top">
        <div className="container-fluid">
          <div className="navbar-header">
            <button type="button" className="navbar-toggle" onClick={this.toggleMenu}>
              <i className="sg-navbar__link fa fa-bars"></i>
            </button>
            <a href={settings.siteUrl} target="_blank" rel="noopener noreferrer" className="navbar-brand"><img src={settings.brand && settings.brand.logo} alt={settings.brand && settings.brand.name} /></a>
          </div>

          {/* Right Menu Side */}
          <div className={classNames({ 'navbar-collapse': true, 'collapse': menuCollapsed })}>
            <ul className="nav navbar-nav">
              <li>
                <Link to="/items-user" className="sg-navbar__link" onClick={this.closeMenu}><WurdText id="common.nav.withUser" /></Link>
              </li>
              <li>
                <Link to="/items-storage" className="sg-navbar__link" onClick={this.closeMenu}><WurdText id="common.nav.inStorage" /></Link>
              </li>
              <li>
                <Link to="/order-boxes" className="sg-navbar__link" onClick={this.closeMenu}><WurdText id="common.nav.order" /></Link>
              </li>
              {settings.helpUrl &&
                <li>
                  <a href={settings.helpUrl} className="sg-navbar__link" target="_blank" rel="noopener noreferrer" onClick={this.closeMenu}><WurdText id="common.nav.help" /></a>
                </li>
              }
              {settings.helpPhone && settings.helpPhone.display &&
                <li>
                  <a href={`tel:${settings.helpPhone.full}`} className="sg-navbar__link" target="_blank" rel="noopener noreferrer" onClick={this.closeMenu}><i className="fa fa-phone"></i> {settings.helpPhone.display}</a>
                </li>
              }
            </ul>

            <ul className="nav navbar-nav navbar-right">
              <li className={classNames({ dropdown: true, open: !dropdownCollapsed })}>
                <a href="#" className="sg-navbar__link dropdown-toggle" onClick={this.toggleDropdown}>{user.firstName + ' ' + user.lastName} <span className="caret"></span></a>
                <ul className="sg-navbar__dropdown dropdown-menu">
                  <li><Link to="/profile" onClick={this.closeMenu} className="sg-navbar__link"><WurdText id="common.nav.profile" /></Link></li>
                  <li><Link to="/invoices" onClick={this.closeMenu} className="sg-navbar__link"><WurdText id="common.nav.invoices" /></Link></li>
                  {settings.billing &&
                    <li><Link to="/billing" onClick={this.closeMenu} className="sg-navbar__link"><WurdText id="common.nav.billing" /></Link></li>
                  }
                  <li><Link to="/" onClick={logout} className="sg-navbar__link"><WurdText id="common.nav.logout" /></Link></li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
  }
});

export default withRouter(Header);
